import "./App.css";
import { Route, Routes, useSearchParams } from "react-router-dom";
import ForgetPass from "./Pages/auth/ForgetPass";
import Login from "./Pages/auth/Login";
import ResetPass from "./Pages/auth/ResetPass";
import Invoices from "./Pages/invoices/Invoices";
import OrderList from "./Pages/orderscreen/OrderList";
import RestrictedPage from "./Pages/RestrictedPage";
import OngoingOrder from "./Pages/ongoingOrders/NewOrder";
import Order from "./Pages/order/Order";
import AuthLayout from "./layout/AuthLayout";
import { GlobalProvider } from "./context/GlobalContext";
import Profile from "./Pages/dashboard/Profile";
import PasswordChange from "./Pages/dashboard/PasswordChange";
import HelmetHeader from "./components/order/HelmetHeader";
import OrderNotification from "./Pages/onlineordernotification/OrderNotification";
import OrderSummary from "./Pages/Summary/OrderSummary";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

function App() {

  const userData = useSelector((state) => state.user.userData);
  const role = userData?.role ?? "R_3";

  return (
    <>
      <HelmetHeader />
      <GlobalProvider>
        <Routes>
          <Route path="/" index exact element={<Login />} />
          <Route path="/forget-password" exact element={<ForgetPass />} />
          <Route path="/reset-password" exact element={<ResetPass />} />
          <Route path="/" element={<AuthLayout />}>
            <Route
              path="/ongoing-orders"
              element={<OngoingOrder />}
            />
            <Route
              path="/orders"
              element={<Order />} />
            <Route
              path="/invoices"
              element={<Invoices />} />


            <Route path='/order-summary' element={<OrderSummary />} />
            <Route
              path="/profile-update"
              element={<Profile />}
            />
            <Route
              path="/password-change"
              element={<PasswordChange />}
            />
          </Route>
          {role !== 'R_4' &&
            <Route
              path="/order-screen"
              element={<OrderList />} />
          }
          {role !== 'R_4' &&
            <Route path="/order-notification" element={<OrderNotification />} />
          }
          {/* Page For Restricted condition */}
          <Route path="/restricted-page" element={<RestrictedPage />} />
        </Routes>
      </GlobalProvider>
      <ToastContainer position="top-center" />
    </>
  );
}

export default App;
