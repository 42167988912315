import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const OtherPizzaSelection = ({ ...props }) => {
  const {
    count,
    otherPizzaData,
    pizzaState,
    handleCrustChange,
    handleCrustTypeChange,
    handleCheeseChange,
    handleSpecialBasesChange,
    handleSauseChange,
    handleSpicyChange,
    handleCookChange,
    toppingsData,
    handleChangeAllIndianToppings,
    handleTwoToppings,
    handleCountAsTwoToppingsPlacementChange,
    handleOneToppings,
    handleCountAsOneToppingsPlacementChange,
    handleFreeToppings,
    handleFreeToppingsPlacementChange,
  } = props;

  return (
    <div key={`pizza-${uuidv4()}`} className="jumbotron">
      <div className="row my-2">
        {/* Crust */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Crust</label>
          <select
            key={`crust-${count}`}
            id={`crust-${count}`}
            className="form-select"
            value={pizzaState[count - 1]?.crust?.crustCode}
            onChange={(e) => {
              handleCrustChange(e, count);
            }}
          >
            {otherPizzaData?.crusts?.map((item, index) => {
              return (
                <option key={item?.code + index + uuidv4()} value={item?.code}>
                  {item?.crustName}- $ {item?.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* Crust Type */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Crust Type</label>
          <select
            key={`crust-type-${uuidv4()}`}
            id={`crust-type-${count}`}
            className="form-select"
            value={pizzaState[count - 1]?.crustType?.crustTypeCode}
            onChange={(e) => {
              handleCrustTypeChange(e, count);
            }}
          >
            {otherPizzaData?.crustType?.map((item, index) => {
              return (
                <option
                  key={item?.crustTypeCode + index + uuidv4()}
                  value={item?.crustTypeCode}
                >
                  {item?.crustType}- $ {item?.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* Cheese */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Cheese</label>
          <select
            key={`cheese-${uuidv4()}`}
            id={`cheese-${count}`}
            className="form-select"
            value={pizzaState[count - 1]?.cheese?.cheeseCode}
            onChange={(e) => {
              handleCheeseChange(e, count);
            }}
          >
            {otherPizzaData?.cheeses?.map((item, index) => {
              return (
                <option key={item?.code + index + uuidv4()} value={item?.code}>
                  {item.cheeseName}- $ {item.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* Special Base */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Special Base</label>
          <select
            key={`spb-${uuidv4()}`}
            id={`spb-${count}`}
            className="form-select"
            value={pizzaState[count - 1]?.specialBase?.specialbaseCode}
            onChange={(e) => {
              handleSpecialBasesChange(e, count);
            }}
          >
            {otherPizzaData?.specialBases?.map((item, index) => {
              return (
                <option key={item?.code + index + uuidv4()} value={item?.code}>
                  {item.specialbaseName}- $ {item.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* Cook */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Cook</label>
          <select
            key={`ck-${uuidv4()}`}
            id={`ck-${count}`}
            className="form-select"
            value={pizzaState[count - 1]?.cook?.cookCode}
            onChange={(e) => {
              handleCookChange(e, count);
            }}
          >
            {otherPizzaData?.cooks?.map((item, index) => {
              return (
                <option
                  key={item?.cookCode + index + uuidv4()}
                  value={item?.cookCode}
                >
                  {item.cook}- $ {item.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* Sauce */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Sauce</label>
          <select
            key={`sauce-${uuidv4()}`}
            id={`sauce-${count}`}
            className="form-select"
            onChange={(e) => {
              handleSauseChange(e, count);
            }}
          >
            {otherPizzaData?.sauces?.map((item, index) => {
              return (
                <option
                  key={item?.sauceCode + index + uuidv4()}
                  value={item?.sauceCode}
                >
                  {item.sauce}- $ {item.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* Spice */}
        <div className="col-lg-4 col-md-4">
          <label className="mt-2 mb-1">Spice</label>
          <select
            key={`spice-${count}`}
            id={`spice-${count}`}
            className="form-select"
            onChange={(e) => {
              handleSpicyChange(e, count);
            }}
          >
            {otherPizzaData?.spicy?.map((item, index) => {
              return (
                <option
                  key={item?.spicyCode + index + uuidv4()}
                  value={item?.spicyCode}
                >
                  {item.spicy}- $ {item.price}
                </option>
              );
            })}
          </select>
        </div>
        {/* indian-style free toppings */}
        <div className="col-sm-12 mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              checked={
                pizzaState[count - 1]?.toppings?.freeToppings?.length ===
                toppingsData?.toppings?.freeToppings?.length
                  ? true
                  : false
              }
              id={`allIndianTps-${count}`}
              onChange={(e) => {
                handleChangeAllIndianToppings(e, count);
              }}
            />
            <label className="form-check-label" htmlFor={`allIndianTps-${count}`}>
              All Indian Style
            </label>
          </div>
        </div>

        {/* toppings section */}
        <div className="mt-3">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <Link
                className="nav-link active py-2 px-4"
                data-bs-toggle="tab"
                to={`#toppings-count-2-tab-other${count}`}
              >
                Toppings (2)
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link py-2 px-4"
                data-bs-toggle="tab"
                to={`#toppings-count-1-tab-other${count}`}
              >
                Toppings (1)
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link py-2 px-4"
                data-bs-toggle="tab"
                to={`#toppings-free-tab-other${count}`}
              >
                Indian Style (Free)
              </Link>
            </li>
          </ul>
          <div className="tab-content m-0 p-0 w-100">
            {/* Count 2 Toppings */}
            <div
              id={`toppings-count-2-tab-other${count}`}
              className="container tab-pane active m-0 p-0 topping-list"
            >
              {toppingsData?.toppings?.countAsTwo?.map(
                (countAsTwoToppings, index) => {
                  let comm = pizzaState[
                    count - 1
                  ]?.toppings?.countAsTwoToppings?.findIndex(
                    (item) =>
                      item.toppingsCode === countAsTwoToppings.toppingsCode
                  );

                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      key={countAsTwoToppings.toppingsCode + index + uuidv4()}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={countAsTwoToppings.toppingsCode}
                          checked={comm !== -1 ? true : false}
                          onChange={(e) => {
                            handleTwoToppings(e, count, countAsTwoToppings);
                          }}
                          id={`${countAsTwoToppings.toppingsCode}-${index}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${countAsTwoToppings.toppingsCode}-${index}`}
                        >
                          {countAsTwoToppings.toppingsName}
                        </label>
                      </div>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "12rem" }}
                      >
                        <span
                          className="mx-2 mb-0 text-end"
                          style={{ width: "35%" }}
                        >
                          $ {countAsTwoToppings.price}
                        </span>
                        <select
                          disabled={comm !== -1 ? false : true}
                          className="form-select d-inline-block"
                          style={{ width: "65%" }}
                          value={
                            pizzaState[count - 1]?.toppings?.countAsTwoToppings[
                              comm
                            ]?.toppingsPlacement
                          }
                          id={`placement-${count - 1}-${
                            countAsTwoToppings.toppingsCode
                          }`}
                          key={`placement-${count - 1}-${
                            countAsTwoToppings.toppingsCode
                          }`}
                          onChange={(e) => {
                            handleCountAsTwoToppingsPlacementChange(
                              e,
                              count,
                              countAsTwoToppings.toppingsCode
                            );
                          }}
                        >
                          <option
                            value="whole"
                            key={countAsTwoToppings.toppingsCode + "w"}
                            selected={
                              pizzaState[count - 1]?.toppings
                                ?.countAsTwoToppings?.length === 0
                                ? true
                                : false
                            }
                          >
                            Whole
                          </option>
                          <option
                            key={countAsTwoToppings.toppingsCode + "l"}
                            value="lefthalf"
                          >
                            Left Half
                          </option>
                          <option
                            key={countAsTwoToppings.toppingsCode + "r"}
                            value="righthalf"
                          >
                            Right Half
                          </option>
                          <option
                            key={countAsTwoToppings.toppingsCode + "q"}
                            value="1/4"
                          >
                            1/4
                          </option>
                        </select>
                      </div>
                    </li>
                  );
                }
              )}
            </div>
            {/* Count 1 Toppings */}
            <div
              id={`toppings-count-1-tab-other${count}`}
              className="container tab-pane m-0 p-0 topping-list"
            >
              {toppingsData?.toppings?.countAsOne?.map(
                (countAsOneToppings, index) => {
                  const comm = pizzaState[
                    count - 1
                  ]?.toppings?.countAsOneToppings?.findIndex(
                    (item) =>
                      item.toppingsCode === countAsOneToppings.toppingsCode
                  );

                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      key={countAsOneToppings.toppingsCode + uuidv4()}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          checked={comm !== -1 ? true : false}
                          onChange={(e) =>
                            handleOneToppings(e, count, countAsOneToppings)
                          }
                          id={`${countAsOneToppings.toppingsCode}-${index}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${countAsOneToppings.toppingsCode}-${index}`}
                        >
                          {countAsOneToppings.toppingsName}
                        </label>
                      </div>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "12rem" }}
                      >
                        <span
                          className="mx-2 mb-0 text-end"
                          style={{ width: "35%" }}
                        >
                          $ {countAsOneToppings.price}
                        </span>
                        <select
                          className="form-select d-inline-block"
                          style={{ width: "65%" }}
                          disabled={comm !== -1 ? false : true}
                          value={
                            pizzaState[count - 1]?.toppings?.countAsOneToppings[
                              comm
                            ]?.toppingsPlacement
                          }
                          key={`placement-${count - 1}-${
                            countAsOneToppings.toppingsCode
                          }`}
                          onChange={(e) => {
                            handleCountAsOneToppingsPlacementChange(
                              e,
                              count,
                              countAsOneToppings.toppingsCode
                            );
                          }}
                        >
                          <option
                            value="whole"
                            key={countAsOneToppings.toppingsCode + "w"}
                            selected={
                              pizzaState[count - 1]?.toppings
                                ?.countAsOneToppings?.length === 0
                                ? true
                                : false
                            }
                          >
                            Whole
                          </option>
                          <option
                            key={countAsOneToppings.toppingsCode + "l"}
                            value="lefthalf"
                          >
                            Left Half
                          </option>
                          <option
                            key={countAsOneToppings.toppingsCode + "r"}
                            value="righthalf"
                          >
                            Right Half
                          </option>
                          <option
                            key={countAsOneToppings.toppingsCode + "q"}
                            value="1/4"
                          >
                            1/4
                          </option>
                        </select>
                      </div>
                    </li>
                  );
                }
              )}
            </div>
            {/* Free Toppings */}
            <div
              id={`toppings-free-tab-other${count}`}
              className="container tab-pane m-0 p-0 topping-list"
            >
              {toppingsData?.toppings?.freeToppings?.map(
                (freeToppings, index) => {
                  const comm = pizzaState[
                    count - 1
                  ]?.toppings?.freeToppings?.findIndex(
                    (item) => item.toppingsCode === freeToppings.toppingsCode
                  );
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      key={freeToppings.toppingsCode + uuidv4()}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          checked={comm !== -1 ? true : false}
                          onChange={(e) =>
                            handleFreeToppings(e, count, freeToppings)
                          }
                          id={`${freeToppings.toppingsCode}-${index}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${freeToppings.toppingsCode}-${index}`}
                        >
                          {freeToppings.toppingsName}
                        </label>
                      </div>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ width: "12rem" }}
                      >
                        <p
                          className="mx-2 mb-0 text-end"
                          style={{ width: "35%" }}
                        >
                          $ {freeToppings?.price}
                        </p>
                        <select
                          className="form-select d-inline-block"
                          style={{ width: "65%" }}
                          value={
                            pizzaState[count - 1]?.toppings?.freeToppings[comm]
                              ?.toppingsPlacement
                          }
                          key={`placement-${count - 1}-${
                            freeToppings.toppingsCode
                          }`}
                          disabled={comm !== -1 ? false : true}
                          onChange={(e) => {
                            handleFreeToppingsPlacementChange(
                              e,
                              count,
                              freeToppings.toppingsCode
                            );
                          }}
                        >
                          <option
                            value="whole"
                            key={freeToppings.toppingsCode + "w"}
                            selected={
                              pizzaState[count - 1]?.toppings?.freeToppings
                                ?.length === 0
                                ? true
                                : false
                            }
                          >
                            Whole
                          </option>
                          <option
                            key={freeToppings.toppingsCode + "l"}
                            value="lefthalf"
                          >
                            Left Half
                          </option>
                          <option
                            key={freeToppings.toppingsCode + "r"}
                            value="righthalf"
                          >
                            Right Half
                          </option>
                          <option
                            key={freeToppings.toppingsCode + "q"}
                            value="1/4"
                          >
                            1/4
                          </option>
                        </select>
                      </div>
                    </li>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherPizzaSelection;
