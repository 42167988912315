import React from "react";

const PizzaToppingsList = ({ ...props }) => {
  const { toppingsFree, toppingsOne, toppingsTwo, index } = props;
  return (
    <div
      className="pizza-toppings-list d-flex flex-row flex-wrap align-items-start justify-content-start d-none"
      key={`pz-toppins-${index}`}
    >
      <span className="me-2">
        <strong>Toppings:</strong>
      </span>
      {toppingsTwo?.map((topping, index) => (
        <span key={`pz-toppins-two-${index}`}>{topping?.title + ","}</span>
      ))}
      {toppingsOne?.map((topping, index) => (
        <span key={`pz-toppins-one-${index}`}>{topping?.title + ","}</span>
      ))}
      {toppingsFree?.length === 6 ? (
        <span>Indian Style</span>
      ) : (
        toppingsFree?.map((topping, index) => (
          <span key={`pz-toppins-free-${index}`}>{topping?.title + ","}</span>
        ))
      )}
    </div>
  );
};

export default PizzaToppingsList;
