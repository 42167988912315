import React, { useEffect } from "react";

export const SelectDropDownCrust = ({
  crustSelected,
  handleCrustChange,
  allIngredients,
  count
}) => {
  useEffect(() => { }, [crustSelected]);
  return (
    <select
      className="form-select"
      id={"crust" + count}
      //defaultValue={"Regular"}
      value={crustSelected?.crustCode}
      onChange={handleCrustChange}
      required
    >
      {allIngredients?.crust?.map((crustData, index) => {
        return (
          <option
            key={"crustDropDown" + index}
            value={crustData?.crustCode}
          >
            {crustData.crustName} - ${crustData.price}
          </option>
        );
      })}
    </select>
  );
};

export const SelectDropDownCrustType = ({
  crustType,
  handleCrustTypeChange,
  allIngredients,
  count
}) => {
  useEffect(() => { }, [crustType]);
  return (
    <select
      className="form-select"
      id={"crustType" + count}
      //defaultValue={""}
      value={crustType?.crustTypeCode}
      onChange={handleCrustTypeChange}
      required
    >
      {allIngredients?.crustType?.map((crustTypeData, index) => {
        return (
          <option
            key={"crustDropDown" + index}
            value={crustTypeData?.crustTypeCode}
          >
            {crustTypeData.crustType} - ${crustTypeData.price}
          </option>
        );
      })}
    </select>

  );
};

export const SelectDropDownCheese = ({
  cheeseSelected,
  handleCheeseChange,
  allIngredients,
  count
}) => {
  return (
    <select
      className="form-select"
      id={"cheese" + count}
      //defaultValue={"Mozzarella"}
      value={cheeseSelected?.cheeseCode}
      onChange={handleCheeseChange}
      required
    >
      {allIngredients?.cheese?.map((item, index) => {
        return (
          <option
            key={"cheese" + index}
            value={item?.cheeseCode}
          >
            {item.cheeseName} - ${item.price}
          </option>
        );
      })}
    </select>
  );
};

export const SelectDropDownSpecialBases = ({
  allIngredients,
  handleSpecialBasesChange,
  specialBasesSelected,
  count
}) => {
  return (
    <select
      className="form-select"
      id={"specialbases" + count}
      onChange={handleSpecialBasesChange}
      //defaultValue={""}
      value={specialBasesSelected?.specialbaseCode}
    >
      <option value={""}>--- choose any one ---</option>
      {allIngredients?.specialbases?.map((specialbasesData, index) => {
        return (
          <option
            key={specialbasesData.specialbaseCode + "specialbase" + index}
            data-price={specialbasesData.price}
            value={specialbasesData?.specialbaseCode}
          >
            {specialbasesData.specialbaseName} - ${specialbasesData.price}
          </option>
        );
      })}
    </select>
  );
};

export const SelectDropDownCook = ({
  allIngredients,
  handleCookChange,
  cookSelected,
  count
}) => {
  return (
    <select
      className="form-select"
      id={"cook" + count}
      onChange={handleCookChange}
      //defaultValue={""}
      value={cookSelected?.cookCode}
      required
    >
      {allIngredients?.cook?.map((cook, index) => {
        return (
          <option
            key={cook.cook + "createyourown" + index}
            data-price={cook.price}
            value={cook?.cookCode}
          >
            {cook.cook} - ${cook.price}
          </option>
        );
      })}
    </select>
  );
};

export const SelectDropDownSause = ({
  allIngredients,
  handleSauseChange,
  sauseSelected,
  count
}) => {
  return (
    <select
      className="form-select"
      id={"sauce" + count}
      onChange={handleSauseChange}
      //defaultValue={""}
      value={sauseSelected?.sauceCode}
      required
    >
      {allIngredients?.sauce?.map((sauce, index) => {
        return (
          <option
            key={sauce.sauce + "createyourown" + index}
            data-price={sauce.price}
            value={sauce?.sauceCode}
          >
            {sauce.sauce} - ${sauce.price}
          </option>
        );
      })}
    </select>
  );
};

export const SelectDropDownSpicy = ({
  allIngredients,
  handleSpicyChange,
  spicySelected,
  count
}) => {
  return (
    <select
      className="form-select"
      id={"spice" + count}
      onChange={handleSpicyChange}
      //defaultValue={""}
      value={spicySelected?.spicyCode}
      required
    >
      {allIngredients?.spices?.map((spices, index) => {
        return (
          <option
            key={spices.spicy + "createyourown" + index}
            data-price={spices.price}
            value={spices?.spicyCode}
          >
            {spices.spicy} - ${spices.price}
          </option>
        );
      })}
    </select>
  );
};
