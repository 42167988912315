import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, setDisplayOtherPizzaForm } from "../../reducer/cartReducer";
import { v4 as uuidv4 } from "uuid";
import {
  allOtherPizzasApi,
  otherPizzaApi,
  toppingsDataByPizzaConfig,
} from "../../API/http";
import { toast } from "react-toastify";
import { BiChevronLeftCircle } from "react-icons/bi";
import LoadingLayout from "../../layout/LoadingLayout";
import OtherPizzaSelection from "../../components/order/OtherPizzaSelection";
import OtherPIzzaItem from "../../components/_main/OtherPIzzaItem";

const OtherPizza = ({ ...props }) => {
  const { payloadEdit, setPayloadEdit, otherTabRef, section, setSection } =
    props;

  const dispatch = useDispatch();

  let cartdata = useSelector((state) => state.cart.cart);

  const displayOtherPizzaForm = useSelector(
    (state) => state.cart.displayOtherPizzaForm
  );

  const numberOfPizzas = "1";

  let calcOneTpsArr = [];
  let calcTwoTpsArr = [];

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otherPizzas, setOtherPizzas] = useState([]);
  const [pizzaState, setPizzaState] = useState([]);
  const [otherPizzaData, setOtherPizzaData] = useState(null);
  const [price, setPrice] = useState(0);
  const [pizzaSize, setPizzaSize] = useState("");
  const [pizzaSizePrice, setPizzaSizePrice] = useState(0);
  const [crustSelected, setCrustSelected] = useState();
  const [crustTypeSelected, setCrustTypeSelected] = useState();
  const [specialBasesSelected, setSpecialBasesSelected] = useState();
  const [cheeseSelected, setCheeseSelected] = useState();
  const [sauceSelected, setSauceSelected] = useState();
  const [spiceSelected, setSpiceSelected] = useState();
  const [cookSelected, setCookSelected] = useState();
  const [toppingsData, setToppingsData] = useState();
  const [isAllIndiansTps, setIsAllIndiansTps] = useState(false);
  const [totalPriceOfToppings, setTotalPriceOfToppings] = useState(0);
  const [allToppings, setAllToppings] = useState([]);
  const [comments, setComments] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [prId, setPrId] = useState(null);
  const pizzaSizeRef = useRef();

  const handleGetOtherPizza = async (code) => {
    setLoading(true);
    await otherPizzaApi(code)
      .then((res) => {
        setOtherPizzaData(res.data.data);
        toppings(code, "other");
        dispatch(setDisplayOtherPizzaForm(true));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(setDisplayOtherPizzaForm(false));
      });
  };

  const createEmptyObjects = (count) => {
    if (otherPizzaData) {
      let crusts = otherPizzaData?.crusts ?? [];
      let cheeses = otherPizzaData?.cheeses ?? [];
      let crustTypes = otherPizzaData?.crustType ?? [];
      let specialBases = otherPizzaData?.specialBases ?? [];
      let cooks = otherPizzaData?.cooks ?? [];
      let sauces = otherPizzaData?.sauces ?? [];
      let spicies = otherPizzaData?.spicy ?? [];

      let crustObj = crusts.find(
        (item) => item.code === otherPizzaData?.crust.code
      );
      let cheeseObj = cheeses.find(
        (item) => item.code === otherPizzaData?.cheese.code
      );
      let crustTypeObj = crustTypes.find(
        (item) => item.code === otherPizzaData?.crust_type.code
      );
      let specialbaseObj = specialBases.find(
        (item) => item.code === otherPizzaData?.special_base.code
      );
      let cookObj = cooks.find(
        (item) => item.code === otherPizzaData?.spices.code
      );
      let sauceObj = sauces.find(
        (item) => item.code === otherPizzaData?.sauce.code
      );
      let spiceObj = spicies.find(
        (item) => item.code === otherPizzaData?.cook.code
      );

      let tpsTwo,
        tpsOne,
        tpsFree = [];

      tpsTwo = otherPizzaData?.topping_as_2.map((item) => {
        const newItem = {
          toppingsCode: item.code,
          toppingsName: item.title,
          toppingsPrice: Number(item.price ?? 0).toFixed(2),
          toppingsPlacement: "whole",
          pizzaIndex: 0,
          amount: Number(item.price ?? 0).toFixed(2),
        };
        return newItem;
      });

      tpsOne = otherPizzaData?.topping_as_1.map((item) => {
        const newItem = {
          toppingsCode: item.code,
          toppingsName: item.title,
          toppingsPrice: Number(item.price ?? 0).toFixed(2),
          toppingsPlacement: "whole",
          pizzaIndex: 0,
          amount: Number(item.price ?? 0).toFixed(2),
        };
        return newItem;
      });

      tpsFree = otherPizzaData?.topping_as_free.map((item) => {
        const newItem = {
          toppingsCode: item.code,
          toppingsName: item.title,
          toppingsPrice: Number(item.price ?? 0).toFixed(2),
          toppingsPlacement: "whole",
          pizzaIndex: 0,
          amount: Number(item.price ?? 0).toFixed(2),
        };
        return newItem;
      });

      const emptyObjectsArray = Array.from({ length: count }, (index) => ({
        crust: {
          crustCode: otherPizzaData?.crust.code,
          crustName: otherPizzaData?.crust.title,
          price: crustObj ? Number(crustObj.price) : Number(0),
        },
        cheese: {
          cheeseCode: otherPizzaData?.cheese.code,
          cheeseName: otherPizzaData?.cheese.title,
          price: cheeseObj ? Number(cheeseObj.price) : Number(0),
        },
        crustType: {
          crustTypeCode: otherPizzaData?.crust_type.code,
          crustType: otherPizzaData?.crust_type.title,
          price: crustTypeObj ? Number(crustTypeObj.price) : Number(0),
        },
        specialBases: {
          specialbaseCode: otherPizzaData?.special_base.code,
          specialbaseName: otherPizzaData?.special_base.title,
          price: specialbaseObj ? Number(specialbaseObj.price) : Number(0),
        },
        cook: {
          cookCode: otherPizzaData?.cook.code,
          cook: otherPizzaData?.cook.title,
          price: cookObj ? Number(cookObj.price) : Number(0),
        },
        sauce: {
          sauceCode: otherPizzaData?.sauce.code,
          sauce: otherPizzaData?.sauce.title,
          price: sauceObj ? Number(sauceObj.price) : Number(0),
        },
        spicy: {
          spicyCode: otherPizzaData?.spices.code,
          spicy: otherPizzaData?.spices.title,
          price: spiceObj ? Number(spiceObj.price) : Number(0),
        },
        toppings: {
          countAsTwoToppings: tpsTwo,
          countAsOneToppings: tpsOne,
          freeToppings: tpsFree,
          isAllIndiansTps: true,
        },
      }));
      setPizzaState(emptyObjectsArray);
      dispatch(setDisplayOtherPizzaForm(true));
    }
  };

  const getAllOtherPizzas = () => {
    setLoading(true);
    allOtherPizzasApi()
      .then((res) => {
        setOtherPizzas(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ERROR From Other Pizza API: ", err);
        setLoading(false);
      });
  };

  const toppings = (code, section) => {
    setLoading(true);
    toppingsDataByPizzaConfig({ code, section })
      .then((res) => {
        setToppingsData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ERROR From Toppings API: ", err);
        setLoading(false);
      });
  };

  const handleBackButton = () => {
    if (
      payloadEdit !== undefined &&
      payloadEdit?.productType === "other_pizza"
    ) {
      toast.warn(
        "You cannot go back until you confirm or edit the selected pizza"
      );
      return;
    } else {
      setQuantity(1);
      setSection("show");
      setPrId(uuidv4());
      setOtherPizzaData(null);
      setPayloadEdit();
      setShow(false);
      setComments("");
      setQuantity(1);
      dispatch(setDisplayOtherPizzaForm(false));
    }
  };

  const handleSizeOfPizza = () => {
    if (pizzaSizeRef.current) {
      setPizzaSize(pizzaSizeRef.current.value);
      const priceObj = otherPizzaData?.pizza_prices.find(
        (item) => item.size === pizzaSizeRef.current.value
      );
      if (priceObj) {
        setPizzaSizePrice(priceObj?.price);
        updateInCart({
          pizzaSize: pizzaSizeRef.current.value,
          pizzaSizePrice: priceObj ? Number(priceObj.price) : Number(0),
        });
      }
    }
  };

  // handle crust change
  const handleCrustChange = (event, count) => {
    const selectedValue = event.target.value;
    const selectedCrust = otherPizzaData?.crusts?.find(
      (option) => option.code === selectedValue
    );
    let crustObject = {
      crustCode: selectedCrust?.code,
      crustName: selectedCrust?.crustName,
      price: selectedCrust?.price,
    };
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      crust: crustObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  // handle crust change
  const handleCrustTypeChange = (event, count) => {
    const selectedValue = event.target.value;
    const selectedCrustType = otherPizzaData?.crustType?.find(
      (option) => option.crustTypeCode === selectedValue
    );
    let crustTypeObject = {
      crustTypeCode: selectedCrustType?.crustTypeCode,
      crustType: selectedCrustType?.crustType,
      price: selectedCrustType?.price,
    };
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      crustType: crustTypeObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  // handle cheese change
  const handleCheeseChange = (event, i) => {
    const selectedValue = event.target.value;
    const selectedCheese = otherPizzaData?.cheeses?.find(
      (option) => option.code === selectedValue
    );
    let cheeseObject = {
      cheeseCode: selectedCheese?.code,
      cheeseName: selectedCheese?.cheeseName,
      price: selectedCheese?.price,
    };
    setPizzaState((prevPizzaState) => {
      const newArr = [...prevPizzaState];
      newArr[i - 1] = {
        ...newArr[i - 1],
        cheese: cheeseObject,
      };
      updateInCart({ pizzaState: newArr });

      return newArr;
    });
  };

  // handle special base change
  const handleSpecialBasesChange = (event, i) => {
    // debugger;
    const selectedValue = event.target.value;
    const selectedSpecialBase = otherPizzaData?.specialBases?.find(
      (option) => option.code === selectedValue
    );
    let specialBaseObject = {
      specialbaseCode: selectedSpecialBase?.code,
      specialbaseName: selectedSpecialBase?.specialbaseName,
      price: selectedSpecialBase?.price,
    };
    let arr = [...pizzaState];
    arr[i - 1] = {
      ...arr[i - 1],
      specialBases: specialBaseObject,
    };
    updateInCart({ pizzaState: arr });

    setPizzaState(arr);
  };

  const handleCookChange = (event, count) => {
    const selectedObject = otherPizzaData?.cooks?.find(
      (option) => option.cookCode === event.target.value
    );
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      cook: selectedObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  const handleSauseChange = (event, count) => {
    const selectedObject = otherPizzaData?.sauces?.find(
      (option) => option.sauceCode === event.target.value
    );
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      sauce: selectedObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  const handleSpicyChange = (event, count) => {
    const selectedObject = otherPizzaData?.spicy?.find(
      (option) => option.spicyCode === event.target.value
    );
    let arr = [...pizzaState];
    arr[count - 1] = {
      ...arr[count - 1],
      spicy: selectedObject,
    };
    updateInCart({ pizzaState: arr });
    setPizzaState(arr);
  };

  const handleChangeAllIndianToppings = (e, count) => {
    if (e.target.checked) {
      const allFreeToppings = [...toppingsData?.toppings?.freeToppings].map(
        (item) => {
          let toppingsPlacement = "whole";
          return {
            toppingsCode: item?.toppingsCode,
            toppingsName: item?.toppingsName,
            toppingsPrice: Number(item?.price),
            toppingsPlacement: toppingsPlacement,
            amount: Number(0.0).toFixed(2),
          };
        }
      );
      let arr = [...pizzaState];
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1].toppings,
          freeToppings: allFreeToppings,
          isAllIndiansTps: true,
        },
      };
      setPizzaState(arr);
      setIsAllIndiansTps(true);
    } else {
      let arr = [...pizzaState];
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1].toppings,
          freeToppings: [],
        },
      };
      setPizzaState(arr);
      setIsAllIndiansTps(false);
    }
  };

  const handleTwoToppings = (e, count, countAsTwoToppings) => {
    const { checked } = e.target;
    delete countAsTwoToppings.image;
    delete countAsTwoToppings?.isPaid;
    delete countAsTwoToppings?.countAs;

    if (checked) {
      let arr = [...pizzaState];
      let ct = count - 1;
      let placement = "whole";
      const tempCountAsTwo = [
        ...(arr[count - 1].toppings?.countAsTwoToppings || []),
        {
          toppingsCode: countAsTwoToppings?.toppingsCode,
          toppingsName: countAsTwoToppings?.toppingsName,
          toppingsPrice: Number(countAsTwoToppings?.price),
          toppingsPlacement: placement,
          pizzaIndex: count - 1,
          amount: Number(countAsTwoToppings?.price).toFixed(2),
        },
      ];

      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1].toppings,
          countAsTwoToppings: tempCountAsTwo,
        },
      };
      setAllToppings([...allToppings, Number(countAsTwoToppings.countAs)]);
      setPizzaState([...arr]);
      updateInCart({ pizzaState: arr });
    } else {
      let arr = [...pizzaState];

      let selectedObject = arr[count - 1]?.toppings?.countAsTwoToppings?.find(
        (option) => option.toppingsCode === countAsTwoToppings?.toppingsCode
      );

      const index = arr[count - 1]?.toppings?.countAsTwoToppings.findIndex(
        (item) => item.toppingsCode === countAsTwoToppings.toppingsCode
      );
      let updatedAll = allToppings.filter(
        (item) => item.toppingsCode !== countAsTwoToppings.toppingsCode
      );
      setAllToppings(updatedAll);
      if (index !== -1 && arr[count - 1]?.toppings?.countAsTwoToppings) {
        let updatedArr = arr[count - 1]?.toppings?.countAsTwoToppings.filter(
          (item) => item.toppingsCode !== countAsTwoToppings.toppingsCode
        );
        arr[count - 1] = {
          ...arr[count - 1],
          toppings: {
            ...arr[count - 1].toppings,
            countAsTwoToppings: [...updatedArr],
          },
        };
      }
      updateInCart({ pizzaState: [...arr] });

      setPizzaState([...arr]);
    }
  };

  const handleCountAsTwoToppingsPlacementChange = (
    event,
    count,
    toppingsCode
  ) => {
    const selectedValue = event.target.value;
    let arr = [...pizzaState];
    let selectedObject = arr[count - 1]?.toppings?.countAsTwoToppings?.find(
      (option) => option.toppingsCode === toppingsCode
    );
    selectedObject = {
      ...selectedObject,
      toppingsPlacement: selectedValue,
    };
    let indexOfSelectedObject = arr[
      count - 1
    ]?.toppings?.countAsTwoToppings?.findIndex(
      (option) => option.toppingsCode === toppingsCode
    );

    if (indexOfSelectedObject !== -1) {
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1]?.toppings,
          countAsTwoToppings: [
            ...arr[count - 1]?.toppings?.countAsTwoToppings.slice(
              0,
              indexOfSelectedObject
            ),
            selectedObject,
            ...arr[count - 1]?.toppings?.countAsTwoToppings.slice(
              indexOfSelectedObject + 1
            ),
          ],
        },
      };
      updateInCart({ pizzaState: arr });
    }
    setPizzaState(arr);
  };

  const handleOneToppings = (e, count, countAsOneToppings) => {
    const { checked } = e.target;
    delete countAsOneToppings.image;
    delete countAsOneToppings?.isPaid;
    delete countAsOneToppings?.countAs;

    if (checked) {
      let ct = count - 1;
      let placement = "whole";

      setPizzaState((prevState) => {
        const newState = [...prevState];
        const pizzaIndex = count - 1;
        newState[pizzaIndex] = {
          ...newState[pizzaIndex],
          toppings: {
            ...newState[pizzaIndex].toppings,
            countAsOneToppings: [
              ...(newState[pizzaIndex].toppings.countAsOneToppings || []),
              {
                toppingsCode: countAsOneToppings?.toppingsCode,
                toppingsName: countAsOneToppings?.toppingsName,
                toppingsPrice: Number(countAsOneToppings?.price),
                toppingsPlacement: placement,
                pizzaIndex: count - 1,
                amount: Number(countAsOneToppings?.price).toFixed(2),
              },
            ],
          },
        };

        setAllToppings([...allToppings, Number(countAsOneToppings.countAs)]);
        updateInCart({ pizzaState: newState });
        return newState;
      });
    } else {
      setPizzaState((prevState) => {
        const newState = [...prevState];
        const pizzaIndex = count - 1;

        const index = newState[
          pizzaIndex
        ]?.toppings?.countAsOneToppings.findIndex(
          (item) => item.toppingsCode === countAsOneToppings.toppingsCode
        );

        if (index !== -1) {
          const updatedArr = newState[
            pizzaIndex
          ]?.toppings?.countAsOneToppings.filter(
            (item) => item.toppingsCode !== countAsOneToppings.toppingsCode
          );

          newState[pizzaIndex] = {
            ...newState[pizzaIndex],
            toppings: {
              ...newState[pizzaIndex].toppings,
              countAsOneToppings: [...updatedArr],
            },
          };

          setAllToppings((prevAllToppings) =>
            prevAllToppings.filter(
              (item) => item !== Number(countAsOneToppings.countAs)
            )
          );
        }
        updateInCart({ pizzaState: newState });

        return newState;
      });
    }
  };

  const handleCountAsOneToppingsPlacementChange = (
    event,
    count,
    toppingsCode
  ) => {
    const selectedValue = event.target.value;
    let arr = [...pizzaState];
    let selectedObject = arr[count - 1]?.toppings?.countAsOneToppings?.find(
      (option) => option.toppingsCode === toppingsCode
    );
    selectedObject = {
      ...selectedObject,
      toppingsPlacement: selectedValue,
    };
    let indexOfSelectedObject = arr[
      count - 1
    ]?.toppings?.countAsOneToppings?.findIndex(
      (option) => option.toppingsCode === toppingsCode
    );
    if (indexOfSelectedObject !== -1) {
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1]?.toppings,
          countAsOneToppings: [
            ...arr[count - 1]?.toppings?.countAsOneToppings.slice(
              0,
              indexOfSelectedObject
            ),
            selectedObject,
            ...arr[count - 1]?.toppings?.countAsOneToppings.slice(
              indexOfSelectedObject + 1
            ),
          ],
        },
      };
      updateInCart({ pizzaState: arr });
    }

    setPizzaState(arr);
  };

  const handleFreeToppings = (e, count, freeToppings) => {
    const { checked } = e.target;
    delete freeToppings.image;

    setPizzaState((prevState) => {
      const newState = [...prevState];
      const pizzaIndex = count - 1;

      if (checked) {
        let ct = count - 1;
        let placement = "whole";

        newState[pizzaIndex] = {
          ...newState[pizzaIndex],
          toppings: {
            ...newState[pizzaIndex].toppings,
            freeToppings: [
              ...(newState[pizzaIndex].toppings.freeToppings || []),
              {
                toppingsCode: freeToppings?.toppingsCode,
                toppingsName: freeToppings?.toppingsName,
                toppingsPrice: Number(freeToppings?.price),
                toppingsPlacement: placement,
                amount: Number(0.0).toFixed(2),
              },
            ],
          },
        };
      } else {
        const index = newState[pizzaIndex]?.toppings?.freeToppings?.findIndex(
          (item) => item.toppingsCode === freeToppings.toppingsCode
        );
        if (index !== -1) {
          const updatedArr = newState[
            pizzaIndex
          ]?.toppings?.freeToppings?.filter(
            (item) => item.toppingsCode !== freeToppings.toppingsCode
          );
          newState[pizzaIndex] = {
            ...newState[pizzaIndex],
            toppings: {
              ...newState[pizzaIndex].toppings,
              freeToppings: [...updatedArr],
            },
          };
        }
      }
      updateInCart({ pizzaState: newState });

      return newState;
    });
  };

  const handleFreeToppingsPlacementChange = (event, count, toppingsCode) => {
    const selectedValue = event.target.value;
    let arr = [...pizzaState];
    let selectedObject = arr[count - 1]?.toppings?.freeToppings?.find(
      (option) => option.toppingsCode === toppingsCode
    );
    selectedObject = {
      ...selectedObject,
      toppingsPlacement: selectedValue,
    };
    let indexOfSelectedObject = arr[
      count - 1
    ]?.toppings?.freeToppings?.findIndex(
      (option) => option.toppingsCode === toppingsCode
    );
    if (indexOfSelectedObject !== -1) {
      arr[count - 1] = {
        ...arr[count - 1],
        toppings: {
          ...arr[count - 1]?.toppings,
          freeToppings: [
            ...arr[count - 1]?.toppings?.freeToppings.slice(
              0,
              indexOfSelectedObject
            ),
            selectedObject,
            ...arr[count - 1]?.toppings?.freeToppings.slice(
              indexOfSelectedObject + 1
            ),
          ],
        },
      };
      updateInCart({ pizzaState: arr });
    }
    setPizzaState(arr);
  };

  const newPizzaComponent = () => {
    return Array.from(Array(Number(numberOfPizzas)).keys()).map(
      (pizza, index) => {
        return (
          <OtherPizzaSelection
            key={Number(index) + 1}
            count={index + 1}
            otherPizzaData={otherPizzaData}
            pizzaState={pizzaState}
            handleCrustChange={handleCrustChange}
            handleCrustTypeChange={handleCrustTypeChange}
            handleCheeseChange={handleCheeseChange}
            handleSpecialBasesChange={handleSpecialBasesChange}
            handleSauseChange={handleSauseChange}
            handleSpicyChange={handleSpicyChange}
            handleCookChange={handleCookChange}
            toppingsData={toppingsData}
            handleChangeAllIndianToppings={handleChangeAllIndianToppings}
            handleTwoToppings={handleTwoToppings}
            handleCountAsTwoToppingsPlacementChange={
              handleCountAsTwoToppingsPlacementChange
            }
            handleOneToppings={handleOneToppings}
            handleCountAsOneToppingsPlacementChange={
              handleCountAsOneToppingsPlacementChange
            }
            handleFreeToppings={handleFreeToppings}
            handleFreeToppingsPlacementChange={
              handleFreeToppingsPlacementChange
            }
          />
        );
      }
    );
  };

  const updateInCart = (data) => {
    let cart = JSON.parse(localStorage.getItem("CartData"));
    let pizzas = data?.pizzaState ? data?.pizzaState : pizzaState;
    let tempPayload = [...cartdata];
    let price = 0;
    let sizeofpizza = "";
    if (pizzaSizeRef.current) {
      sizeofpizza = pizzaSizeRef.current.value;
      const priceObj = otherPizzaData?.pizza_prices.find(
        (item) => item.size === pizzaSizeRef.current.value
      );
      if (priceObj) {
        price = priceObj ? Number(priceObj.price) : Number(0);
      }
    }

    let calcOneTpsArr2 = [];
    let calcTwoTpsArr2 = [];
    const calculate = () => {
      let totalPrice = Number(0);
      let totalOneTpsPrice = Number(0);
      let totalTwoTpsPrice = Number(0);
      totalPrice += price;
      let pizzaCartons = [];
      for (let i = 0; i < 1; i++) {
        pizzaCartons.push(i);
      }
      // Iterate through pizzaState
      pizzas?.forEach((item) => {
        totalPrice += item?.cheese ? Number(item?.cheese?.price) : 0;
        totalPrice += item?.crust ? Number(item?.crust?.price) : 0;
        totalPrice += item?.crustType ? Number(item?.crustType?.price) : 0;
        totalPrice += item?.specialBases
          ? Number(item?.specialBases?.price)
          : 0;
        totalPrice += item?.cook ? Number(item?.cook?.price) : 0;
        totalPrice += item?.spicy ? Number(item?.spicy?.price) : 0;
        totalPrice += item?.sauce ? Number(item?.sauce?.price) : 0;
        pizzaCartons.map((pizzaCarton) => {
          if (item?.toppings?.countAsOneToppings?.length > 0) {
            item?.toppings?.countAsOneToppings?.map((oneTippingItem) => {
              if (oneTippingItem.pizzaIndex === pizzaCarton) {
                calcOneTpsArr2.push({
                  ...oneTippingItem,
                  amount: Number(oneTippingItem?.toppingsPrice).toFixed(2),
                });
              }
            });
          }
          if (item?.toppings?.countAsTwoToppings?.length > 0) {
            item?.toppings?.countAsTwoToppings?.map((twoToppingItem) => {
              if (twoToppingItem.pizzaIndex === pizzaCarton) {
                calcTwoTpsArr2.push({
                  ...twoToppingItem,
                  amount: Number(twoToppingItem?.toppingsPrice).toFixed(2),
                });
              }
            });
          }
        });
      });

      if (calcTwoTpsArr2?.length > 0) {
        let arr = [...pizzas];
        calcTwoTpsArr2?.forEach((tpsObj) => {
          arr[tpsObj?.pizzaIndex] = {
            ...arr[tpsObj?.pizzaIndex],
            toppings: {
              ...arr[tpsObj?.pizzaIndex].toppings,
              countAsTwoToppings: [],
            },
          };
        });
        calcTwoTpsArr2?.forEach((tpsObj) => {
          arr[tpsObj?.pizzaIndex] = {
            ...arr[tpsObj?.pizzaIndex],
            toppings: {
              ...arr[tpsObj?.pizzaIndex].toppings,
              countAsTwoToppings: [
                ...arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings,
                tpsObj,
              ],
            },
          };
        });
        pizzas = arr;
      }

      if (calcOneTpsArr2?.length > 0) {
        let arr = [...pizzas];
        calcOneTpsArr2?.forEach((tpsObj) => {
          arr[tpsObj?.pizzaIndex] = {
            ...arr[tpsObj?.pizzaIndex],
            toppings: {
              ...arr[tpsObj?.pizzaIndex].toppings,
              countAsOneToppings: [],
            },
          };
        });
        calcOneTpsArr2?.forEach((tpsObj) => {
          arr[tpsObj?.pizzaIndex] = {
            ...arr[tpsObj?.pizzaIndex],
            toppings: {
              ...arr[tpsObj?.pizzaIndex].toppings,
              countAsOneToppings: [
                ...arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings,
                tpsObj,
              ],
            },
          };
        });
        pizzas = arr;
      }

      let arr_1 = [...pizzas];

      pizzas?.map((item, index) => {
        if (
          toppingsData?.toppings?.freeToppings.length ===
          item?.toppings?.freeToppings.length
        ) {
          if (arr_1[index]) {
            arr_1[index] = {
              ...arr_1[index],
              toppings: {
                ...arr_1[index].toppings,
                isAllIndiansTps: true,
              },
            };
          }
        } else {
          if (arr_1[index]) {
            arr_1[index] = {
              ...arr_1[index],
              toppings: {
                ...arr_1[index].toppings,
                isAllIndiansTps: false,
              },
            };
          }
        }
      });
      pizzas = arr_1;

      calcOneTpsArr2?.forEach((tps) => {
        totalOneTpsPrice += Number(tps?.amount);
      });

      calcTwoTpsArr2?.forEach((tps) => {
        totalTwoTpsPrice += Number(tps?.amount);
      });

      totalPrice += totalOneTpsPrice;
      totalPrice += totalTwoTpsPrice;

      const formattedPrice = Number(totalPrice).toFixed(2);
      return formattedPrice;
    };

    let finalPrice = calculate();
    let finalVal = (Number(quantity) * Number(finalPrice)).toFixed(2);

    let payload = {
      id: prId,
      productCode: otherPizzaData?.code,
      productType: "other_pizza",
      productName: otherPizzaData?.pizza_name,
      config: {
        pizza: pizzas,
        sides: [],
        dips: [],
        drinks: [],
      },
      quantity: quantity,
      price: Number(finalPrice).toFixed(2),
      amount: Number(finalVal),
      comments: data?.comment ? data?.comment : comments,
      pizzaSize: sizeofpizza,
      pizzaPrice: Number(price).toFixed(2),
    };

    if (
      payloadEdit !== undefined &&
      payloadEdit.productType === "other_pizza"
    ) {
      tempPayload = tempPayload.map((item) =>
        item.id === prId ? payload : item
      );
    } else {
      const itemExists = tempPayload.find((item) => item.id === prId);
      if (itemExists) {
        tempPayload = tempPayload.map((item) =>
          item.id === prId ? payload : item
        );
      } else {
        tempPayload.unshift(payload);
      }
    }

    dispatch(addToCart([...tempPayload]));
    setPrice(finalVal);
  };

  const calculatePrice = () => {
    if (otherPizzaData !== undefined && otherPizzaData !== null) {
      let totalPrice = Number(0);
      let totalOneTpsPrice = Number(0);
      let totalTwoTpsPrice = Number(0);
      let size = "";

      if (pizzaSizeRef.current) {
        size = pizzaSizeRef.current.value;
        if (size !== undefined && size !== "") {
          const priceObj = otherPizzaData?.pizza_prices.find(
            (item) => size === item.size
          );
          totalPrice += priceObj ? Number(priceObj.price) : Number(0);
          setPizzaSizePrice(priceObj ? Number(priceObj.price) : Number(0));
        }
      }

      let pizzaCartons = [0];
      pizzaState?.forEach((item) => {
        totalPrice += item?.crust ? Number(item?.crust?.price) : 0;
        totalPrice += item?.crustType ? Number(item?.crustType?.price) : 0;
        totalPrice += item?.cheese ? Number(item?.cheese?.price) : 0;
        totalPrice += item?.specialBases
          ? Number(item?.specialBases?.price)
          : 0;
        totalPrice += item?.cook ? Number(item?.cook?.price) : 0;
        totalPrice += item?.spicy ? Number(item?.spicy?.price) : 0;
        totalPrice += item?.sauce ? Number(item?.sauce?.price) : 0;

        pizzaCartons.map((pizzaCarton) => {
          if (item?.toppings?.countAsOneToppings?.length > 0) {
            item?.toppings?.countAsOneToppings?.map((items) => {
              if (items.pizzaIndex === pizzaCarton) {
                calcOneTpsArr.push({
                  ...items,
                  amount: Number(items?.toppingsPrice).toFixed(2),
                });
              }
            });
          }
          if (item?.toppings?.countAsTwoToppings?.length > 0) {
            item?.toppings?.countAsTwoToppings?.map((items) => {
              if (items.pizzaIndex === pizzaCarton) {
                calcTwoTpsArr.push({
                  ...items,
                  amount: Number(items?.toppingsPrice).toFixed(2),
                });
              }
            });
          }
        });
      });

      calcOneTpsArr?.map((tps) => {
        totalOneTpsPrice += Number(tps?.amount);
      });

      calcTwoTpsArr?.map((tps) => {
        totalTwoTpsPrice += Number(tps?.amount);
      });

      totalPrice += totalOneTpsPrice;
      totalPrice += totalTwoTpsPrice;
      totalPrice = Number(quantity) * totalPrice;
      setPrice(Number(totalPrice).toFixed(2));
    }
  };

  const handleAddToCart = () => {
    if (
      payloadEdit !== undefined &&
      payloadEdit?.productType.toLowerCase() === "other_pizza"
    ) {
      let arr = [...pizzaState];
      pizzaState?.map((item, index) => {
        if (
          toppingsData?.toppings?.freeToppings.length ===
          item?.toppings?.freeToppings.length
        ) {
          if (arr[index]) {
            arr[index] = {
              ...arr[index],
              toppings: {
                ...arr[index].toppings,
                isAllIndiansTps: true,
              },
            };
          }
        } else {
          if (arr[index]) {
            arr[index] = {
              ...arr[index],
              toppings: {
                ...arr[index].toppings,
                isAllIndiansTps: false,
              },
            };
          }
        }
      });
      let finalVal = (Number(quantity) * Number(price)).toFixed(2);
      let payloadForEdit = {
        id: payloadEdit?.id,
        productCode: otherPizzaData?.code,
        productName: otherPizzaData?.pizza_name,
        productType: "other_pizza",
        config: {
          pizza: arr,
          sides: [],
          dips: [],
          drinks: [],
        },
        quantity: quantity,
        price: price,
        amount: finalVal,
        comments: comments,
        pizzaSize: pizzaSize,
        pizzaPrice: pizzaSizePrice,
      };
      const updatedCart = cartdata.findIndex(
        (item) => item.id === payloadEdit.id
      );
      let tempPayload = [...cartdata];
      tempPayload[0] = payloadForEdit;
      dispatch(addToCart([...tempPayload]));
      setQuantity(1);
      setComments("");
      setPayloadEdit();
      setSection("show");
      setPrId(uuidv4());
      setOtherPizzaData(null);
      createEmptyObjects(Number(numberOfPizzas));
      toast.success(
        `${payloadForEdit.productName} updated to cart successfully...`
      );
      dispatch(setDisplayOtherPizzaForm(false));
    } else {
      //update pizza array
      if (calcTwoTpsArr?.length > 0) {
        let arr = [...pizzaState];
        calcTwoTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings = [];
        });
        calcTwoTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings = [
            ...arr[tpsObj?.pizzaIndex].toppings.countAsTwoToppings,
            tpsObj,
          ];
        });
      }
      if (calcOneTpsArr?.length > 0) {
        let arr = [...pizzaState];
        calcOneTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings = [];
        });
        calcOneTpsArr?.map((tpsObj) => {
          arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings = [
            ...arr[tpsObj?.pizzaIndex].toppings.countAsOneToppings,
            tpsObj,
          ];
        });
      }
      let arr = [...pizzaState];
      pizzaState?.map((item, index) => {
        if (
          toppingsData?.toppings?.freeToppings?.length ===
          item?.toppings?.freeToppings?.length
        ) {
          if (arr[index]) {
            arr[index].toppings.isAllIndiansTps = true; // Replace 'true' with the desired value
          }
        } else {
          if (arr[index]) {
            arr[index].toppings.isAllIndiansTps = false; // Replace 'true' with the desired value
          }
        }
      });
      setPizzaState(arr);
      let finalVal = (Number(quantity) * Number(price)).toFixed(2);
      let payload = {
        id: uuidv4(),
        productCode: otherPizzaData?.code,
        productType: "other_pizza",
        productName: otherPizzaData?.pizza_name,
        config: {
          pizza: pizzaState,
          sides: [],
          dips: [],
          drinks: [],
        },
        quantity: quantity,
        price: price,
        amount: finalVal,
        comments: comments,
        pizzaSize: pizzaSize,
        pizzaPrice: pizzaSizePrice,
      };
      dispatch(addToCart([payload, ...cartdata]));
      setComments("");
      setPrice(finalVal);
      setQuantity(1);
      setSection("show");
      createEmptyObjects(Number(numberOfPizzas));
      dispatch(setDisplayOtherPizzaForm(false));
      toast.success(` ${payload.productName} added to cart successfully...`);
    }
  };

  //calculate pizza price
  useEffect(() => {
    calculatePrice();
  }, [
    pizzaState,
    pizzaSize,
    pizzaSizePrice,
    calcOneTpsArr,
    calcTwoTpsArr,
    pizzaSizeRef,
  ]);

  // specific special offer pizza
  useEffect(() => {
    if (!payloadEdit) {
      if (payloadEdit === undefined) {
        createEmptyObjects(Number(numberOfPizzas));
      }
    }
  }, [otherPizzaData]);

  // load all signature pizzas
  useEffect(() => {
    getAllOtherPizzas();
    dispatch(setDisplayOtherPizzaForm(false));
  }, []);

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
    updateInCart({ quantity: e.target.value });
  };

  useEffect(() => {
    if (
      payloadEdit &&
      payloadEdit !== undefined &&
      payloadEdit?.productType === "other_pizza"
    ) {
      setPrId(payloadEdit?.id);
      handleGetOtherPizza(payloadEdit?.productCode);
      setQuantity(payloadEdit?.quantity);
      setPizzaState(payloadEdit?.config?.pizza);
      setPrice(Number(payloadEdit?.amount));
      setComments(payloadEdit?.comments);
      setPizzaSize(payloadEdit?.pizzaSize);
      setPizzaSizePrice(Number(payloadEdit?.pizzaPrice));
    } else {
      setPrId(uuidv4());
    }
  }, [payloadEdit]);

  return (
    <div className="d-flex flex-wrap justify-content-center">
      <div className="w-100">
        {displayOtherPizzaForm ? (
          loading ? (
            <div>
              <LoadingLayout />
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary btn-xs mb-1"
                  onClick={handleBackButton}
                >
                  <BiChevronLeftCircle /> Back
                </button>
                {/* Add/Edit Cart button */}
                {payloadEdit !== undefined &&
                  payloadEdit?.productType.toLowerCase() === "other_pizza" && (
                    <button
                      type="button"
                      className="btn btn-xs mb-1 addToCartbtn"
                      onClick={handleAddToCart}
                    >
                      <i className="fa fa-check"></i>
                      <span>Confirm Edit</span>
                    </button>
                  )}
              </div>
              <div className="customizablePizza px-3 pb-4">
                <div className="d-flex justify-content-between">
                  <div>
                    <h6>
                      {otherPizzaData?.pizza_name}
                      {otherPizzaData?.category?.category_name !== null && (
                        <span className="ms-1 text-danger-dark">
                          ({otherPizzaData?.category?.category_name})
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="mx-2 text-nowrap">
                    <h6>${price}</h6>
                  </div>
                </div>
                <div className="my-1">
                  <div className="row g-3 align-items-center">
                    <div className="col-auto">
                      <label>Size : </label>
                    </div>
                    <div className="col-auto">
                      <select
                        className="form-select"
                        ref={pizzaSizeRef}
                        onChange={handleSizeOfPizza}
                        value={pizzaSize}
                      >
                        {otherPizzaData?.pizza_prices.length > 0 &&
                          otherPizzaData?.pizza_prices.map(
                            (pizza_price, sizeIndex) => {
                              if (Number(pizza_price.price) > 0) {
                                return (
                                  <option
                                    key={`size-${sizeIndex}-other-pizza`}
                                    value={pizza_price.size}
                                  >
                                    {pizza_price.size} - ${pizza_price.price}
                                  </option>
                                );
                              }
                            }
                          )}
                      </select>
                    </div>
                    <div className="col-auto">
                      <label htmlFor="pizzaQty">Quantity</label>
                    </div>
                    <div className="col-auto">
                      <input
                        type="number"
                        id="pizzaQty"
                        className="form-control"
                        min={1}
                        max={20}
                        step={1}
                        value={quantity}
                        onChange={handleQuantityChange}
                      />
                    </div>
                  </div>
                </div>
                {/* new dynamic pizza component */}
                {newPizzaComponent()}
                {/* Comments */}

                <div className="mb-1">
                  <h6 className="text-left mt-1 mb-2">Comments</h6>
                  <textarea
                    className="form-control comment-font"
                    rows="3"
                    cols="50"
                    value={comments}
                    onChange={(e) => {
                      updateInCart({ comment: e.target.value });
                      setComments(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )
        ) : (
          <ul
            key="other-pizza"
            className="list-group"
            style={{ overflowY: "scroll", height: "70vh" }}
          >
            {otherPizzas?.map((otherPizza, index) => {
              return (
                <OtherPIzzaItem
                  key={`other-pizza-${index}`}
                  index={index}
                  pizza={otherPizza}
                  createEmptyObjects={createEmptyObjects}
                  handleGetOtherPizza={handleGetOtherPizza}
                  setSection={setSection}
                />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default OtherPizza;
