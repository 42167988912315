import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToCart } from "../../reducer/cartReducer";
import { v4 as uuidv4 } from "uuid";

const toppingPlacement = {
  whole: "W",
  lefthalf: "L",
  righthalf: "R",
  "1/4": "1/4",
};

const allowedProductTypes = [
  "signature_pizza",
  "other_pizza",
  "special_pizza",
  "custom_pizza",
];

const Cart = ({ setPayloadEdit, payloadEdit, onProductClick, setSection }) => {
  const dispatch = useDispatch();

  const [cartListData, setCartListData] = useState();

  let cartdata = useSelector((state) => state.cart.cart);

  const deleteItemFromCart = (ind) => {
    let tmp = [...cartListData];
    tmp.splice(ind, 1);
    dispatch(addToCart([...tmp]));
    setPayloadEdit();
    toast.error("Item deleted successfully");
  };

  const duplicateItem = (item) => {
    dispatch(addToCart([{ ...item, id: uuidv4() }, ...cartdata]));
  };

  useEffect(() => {
    setCartListData(cartdata);
  }, [cartdata]);

  const moveItemToTop = (item, index) => {
    let tempPayload = [...cartdata];
    let movedObject = tempPayload.splice(index, 1)[0];
    tempPayload.unshift(movedObject);
    dispatch(addToCart([...tempPayload]));
  };

  return (
    <div>
      {cartdata?.map((data, cartIndex) => {
        const sides = data.config.sides || [];
        const dips = data.config.dips || [];
        const drinks = data.config.drinks || [];

        return (
          <div
            key={`cart-item-${data.id}`}
            className="mb-2"
            style={{
              padding:
                payloadEdit !== undefined && cartIndex === 0
                  ? "0.75rem"
                  : "0.5rem",
              background:
                payloadEdit !== undefined && cartIndex === 0
                  ? "#FFF7D9"
                  : "#FFFBEA",
              borderLeftWidth:
                payloadEdit !== undefined && cartIndex === 0 ? "4px" : "2px",
              borderLeftStyle: "solid",
              borderLeftColor:
                payloadEdit !== undefined && cartIndex === 0
                  ? "orange"
                  : "#7f7f7f",
              borderRadius: "0px",
              scrollTop:
                payloadEdit !== undefined && cartIndex === 0 ? "0" : null,
            }}
          >
            <div className="d-flex justify-content-between">
              <h6>{data.productName}</h6>
              <b className="mx-0">${Number(data.amount).toFixed(2)}</b>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-left">
                {allowedProductTypes.includes(
                  data?.productType?.toLowerCase()
                ) ? (
                  <>
                    <h6>Size: </h6>
                    <span className="mx-1">{data.pizzaSize}</span>
                  </>
                ) : data.productType === "side" ? (
                  <>
                    <h6>Size: </h6>
                    <span className="mx-1">{data.config.sidesSize}</span>
                  </>
                ) : null}
              </div>
              <div className="d-flex justify-content-right mx-0 mb-1">
                <h6 className="mx-2">Quantity : </h6>
                <span className="">{data.quantity}</span>
              </div>
            </div>
            {allowedProductTypes.includes(data?.productType?.toLowerCase()) && (
              <div className="d-flex w-100 justify-content-left">
                <div className="mx-1 w-100 ">
                  {data?.config?.pizza?.map((pizza, index) => {
                    const crust = pizza.crust;
                    const crustType = pizza.crustType;
                    const cheese = pizza.cheese;
                    const specialBases = pizza.specialBases;
                    const sauce = pizza.sauce;
                    const cook = pizza.cook;
                    const spicy = pizza.spicy;

                    return (
                      <div
                        className="w-100"
                        key={`pizza-config-${data.id}-${index}`}
                      >
                        <h6 className="border-bottom pb-1">
                          Pizza {index + 1} :
                        </h6>
                        {crust &&
                          crust?.crustName.toLowerCase() !== "regular" && (
                            <div className="d-flex align-items-start">
                              <div className="w-25 fs-600">Crust:</div>
                              <div className="w-75 ms-2">{crust.crustName}</div>
                            </div>
                          )}

                        {crustType &&
                          crustType?.crustType.toLowerCase() !== "regular" && (
                            <div className="d-flex align-items-start">
                              <div className="w-25 fs-600">Crust Type:</div>
                              <div className="w-75 ms-2">
                                {crustType.crustType}
                              </div>
                            </div>
                          )}

                        {spicy && spicy?.spicy.toLowerCase() !== "regular" && (
                          <div className="d-flex align-items-start">
                            <div className="w-25 fs-600">Spicy:</div>
                            <div className="w-75 ms-2">{spicy.spicy}</div>
                          </div>
                        )}

                        {specialBases &&
                          specialBases.specialbaseName !== undefined && (
                            <div className="d-flex align-items-start">
                              <div className="w-25 fs-600">Special Base:</div>
                              <div className="w-75 ms-2">
                                {specialBases.specialbaseName}
                              </div>
                            </div>
                          )}

                        {sauce && sauce?.sauce.toLowerCase() !== "regular" && (
                          <div className="d-flex align-items-start">
                            <div className="w-25 fs-600">Spicy</div>
                            <div className="w-75 ms-2">{sauce.sauce}</div>
                          </div>
                        )}

                        {cheese !== undefined &&
                          cheese?.cheeseName.toLowerCase() !== "regular" && (
                            <div className="d-flex align-items-start">
                              <div className="w-25 fs-600">Cheese:</div>
                              <div className="w-75 ms-2">
                                {cheese.cheeseName}
                              </div>
                            </div>
                          )}

                        {cook && cook?.cook.toLowerCase() !== "regular" && (
                          <div className="d-flex align-items-start">
                            <div className="w-25 fs-600">Cook:</div>
                            <div className="w-75 ms-2">{cook.cook}</div>
                          </div>
                        )}

                        <div key={"pizza-toppings-" + index} className="my-1">
                          <div className="d-flex align-items-start">
                            <div className="w-25 fs-600">Toppings:</div>
                            <div className="w-75 ms-2">
                              {[
                                ...pizza?.toppings?.countAsOneToppings,
                                ...pizza?.toppings?.countAsTwoToppings,
                              ].map((topping, index) => {
                                return (
                                  <div
                                    key={`toppings-${data.id}-${index}`}
                                    className="w-100 m-0"
                                  >
                                    {`${topping?.toppingsName} (${
                                      toppingPlacement[
                                        topping.toppingsPlacement
                                      ]
                                    }) `}
                                  </div>
                                );
                              })}

                              {pizza?.toppings?.isAllIndiansTps === true ? (
                                <div className="w-100 m-0">
                                  Indian Style + Corriander
                                </div>
                              ) : (
                                <div className="w-100 m-0">
                                  {pizza?.toppings?.freeToppings.map(
                                    (topping, index) => {
                                      return (
                                        <div
                                          key={`free-toppings-${data.id}-${index}`}
                                          className="w-100 m-0"
                                        >
                                          {`${topping?.toppingsName} (${
                                            toppingPlacement[
                                              topping.toppingsPlacement
                                            ]
                                          }) `}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {(sides.length > 0 ||
                    dips.length > 0 ||
                    drinks.length > 0) && <div className="border-bottom" />}
                  {sides.length > 0 && (
                    <div className="d-flex align-items-start">
                      <div className="w-25 fs-600">Sides :</div>
                      <div className="ms-2 w-75">
                        {sides.map((side, index) => {
                          return (
                            <div className="" key={`dips-${data.id}-${index}`}>
                              {`${side.sideName} (${side.sideSize}) - ${side.quantity}`}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {dips.length > 0 && (
                    <div className="d-flex align-items-start">
                      <div className="w-25 fs-600">Dips :</div>
                      <div className="ms-2">
                        {dips.map((dip, index) => {
                          return (
                            <div className="">
                              {`${dip.dipsName} - ${dip.quantity}`}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {drinks.length > 0 && (
                    <div className="d-flex align-items-start">
                      <div className="w-25 fs-600">Drinks :</div>
                      <div className="ms-2">
                        {drinks.map((drink, index) => {
                          return (
                            <div className="">
                              {`${drink.drinksName} - ${drink.quantity}`}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {data.comments!=="" && (

              <div className="w-2">Note:{data.comments}</div>
            )}

            <div className="d-flex align-items-center">
              <span
                className="btn m-0 p-0"
                onClick={() => deleteItemFromCart(cartIndex)}
                type="button"
              >
                <i
                  className="fa fa-trash-o"
                  aria-hidden="true"
                  style={{ fontSize: "1.1rem", color: "#ff5555" }}
                ></i>
              </span>
              <span
                className="btn ms-3"
                onClick={() => {
                  if (payloadEdit !== undefined) {
                    toast.error(
                      `Please complete your ${payloadEdit?.productType} edit first`
                    );
                  } else {
                    if(allowedProductTypes.includes(payloadEdit?.productType) ) {
                      setSection("hide");
                    }
                    setPayloadEdit(data);
                    moveItemToTop(data, cartIndex);
                    onProductClick(data.productType);
                  }
                }}
              >
                <i
                  className="fa fa-pencil-square-o cart-edit"
                  aria-hidden="true"
                ></i>
              </span>
              <span
                className="btn"
                onClick={() => {
                  duplicateItem(data);
                }}
              >
                <i className="fa fa-copy cart-copy" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Cart;
