import React, { useEffect, useRef, useState } from "react";
import "../../css/ongoingOrder.css";
import "react-intl-tel-input/dist/main.css";
import "react-toastify/dist/ReactToastify.css";
import SpecialMenu from "./SpecialMenu";
import CreateYourOwn from "../../components/order/CreateYourOwn";
import {
  getCartListApi,
  orderPlaceApi,
  storeLocationApi,
  deliveryExecutiveApi,
  settingsApi,
  orderEditApi,
  getPrevAddressApi,
  allIngredientsApi,
  postalCodeDeliverable,
  prevOrderDetails,
  sidesApi,
  pizzaPriceApi,
  orderDetails,
} from "../../API/http";
import SidesMenu from "./SidesMenu";
import DipsMenu from "./DipsMenu";
import DrinksMenu from "./DrinksMenu";
import $ from "jquery";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import IntlTelInput from "react-intl-tel-input";
import {
  pizzaIngredients,
  pizzaPriceData,
  sidesIngredient,
} from "./newOrder/newOrderCustomApiHandler";
import Cart from "./Cart";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  setDisplayOtherPizzaForm,
  setDisplaySignatureForm,
  setDisplaySpecialForm,
  setUpdateOrder,
  setUpdateOrderData,
} from "../../reducer/cartReducer";
import { useDebounce } from "./newOrder/newOrderFunctions";
import NotDeliverableModel from "./newOrder/model";
import Print from "../order/Print";
import ReactToPrint from "react-to-print";
import { BiTrash } from "react-icons/bi";
import { useSocket } from "../../context/SocketContext";
import ProductTabNav from "../../components/ProductTabNav";
import SignaturePizza from "./SignaturePizza";
import OtherPizza from "./OtherPizza";

const canadianPhoneNumberRegExp = /^\d{3}\d{3}\d{4}$/;

function NewOrder() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const [payloadEdit, setPayloadEdit] = useState();
  const [allIngredients, setAllIngredients] = useState();
  const [pizzaPriceObj, setPizzaPriceObj] = useState();
  const [sidesData, setSidesData] = useState();
  const [deliveryType, setDeliveryType] = useState("pickup");
  const [storesLocationData, setStoreLocationData] = useState([]);
  const [storesCode, setStoresCode] = useState();
  const [discount, setDiscount] = useState(0);
  const [taxPer, setTaxPer] = useState(0);
  const [cartListData, setCartListData] = useState();
  const [deliverExecutiveList, setDeliverExecutiveList] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [cartCode, setCartCode] = useState();
  const [cartLineCode, setCartLineCode] = useState();
  const [settingsData, setSettingsData] = useState();
  const [isOrderSubmittedSuccessfully, setIsOrderSubmittedSuccessfully] =
    useState(false);
  const [prevAddress, setPrevAddress] = useState("");
  const [prevOrderLoading, setPrevOrderLoading] = useState(false);
  const [prevOrders, setPrevOrders] = useState([]);
  const [orderData, setOrderData] = useState();
  const [ispostalcodeAvailable, setIspostalcodeAvailable] = useState(true);
  const [extraDeliveryCharges, setExtraDeliveryCharges] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("createByOwn");
  const [section, setSection] = useState("show");

  const createYourOwnRef = useRef(null);
  const specialTabRef = useRef(null);
  const signatureTabRef = useRef(null);
  const otherPizzaTabRef = useRef(null);
  const dipsRef = useRef(null);
  const sidesRef = useRef(null);
  const drinksRef = useRef(null);
  const printRef2 = useRef();
  const btnRef = useRef();

  const handleProductClick = (productType) => {
    const prType = productType;
    switch (prType) {
      case "custom_pizza":
        createYourOwnRef.current.click();
        break;
      case "side":
        sidesRef.current.click();
        break;
      case "dips":
        dipsRef.current.click();
        break;
      case "drinks":
        drinksRef.current.click();
        break;
      case "special_pizza":
        specialTabRef.current.click();
        dispatch(setDisplaySpecialForm(true));
        break;
      case "signature_pizza":
        signatureTabRef.current.click();
        dispatch(setDisplaySignatureForm(true));
        break;
      case "other_pizza":
        otherPizzaTabRef.current.click();
        dispatch(setDisplayOtherPizzaForm(true));
        break;
      default:
        break;
    }
  };

  let user = useSelector((state) => state.user.userData);
  const role = user?.role ?? "R_3";
  const currentStoreLocation = user?.storeLocation ?? "STR_1";

  let cartData = useSelector((state) => state.cart.cart);
  let updateOrder = useSelector((state) => state.cart.updateOrder);
  let updateOrderData = useSelector((state) => state.cart.updateOrderData);
  let totalPrice = 0;
  cartData.forEach((item) => {
    totalPrice += Number(item.amount);
  });

  const delivery_charges =
    cartData.length !== 0 && deliveryType !== "pickup"
      ? settingsData?.filter(
          (item) => item.settingName === "Delivery Charges"
        )[0].settingValue
      : 0;

  const discountedTotalPrice = totalPrice - (discount ? discount : 0);
  const taxAmount = (discountedTotalPrice * taxPer) / 100;
  const grandTotal =
    discountedTotalPrice +
    taxAmount +
    Number(delivery_charges) +
    Number(extraDeliveryCharges);

  const initialValues = {
    phoneno: "",
    category: "pickup",
    customername: "",
    customeremail:"",
    address: prevAddress ? prevAddress : "",
    stores: user?.role !== "R_4" ? user?.storeLocation : "",
    postalcode: "",
    deliveryExecutive: "",
    orderTakenBy: "",
  };

  //API - Store Location
  const storeLocation = async () => {
    await storeLocationApi()
      .then((res) => {
        setStoreLocationData(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  //deliver executive
  useEffect(() => {
    if (storesLocationData !== undefined) {
      deliveryExecutive(storesLocationData[0]?.code);
    }
  }, [storesLocationData]);

  useEffect(() => {
    if (isOrderSubmittedSuccessfully) {
      btnRef.current.click();
      setIsOrderSubmittedSuccessfully(false);
    }
  }, [isOrderSubmittedSuccessfully]);

  const deliveryExecutive = async (payload) => {
    await deliveryExecutiveApi(payload).then((res) => {
      setDeliverExecutiveList(res.data.data);
    });
  };

  // handle Stores Location --> Store Code
  const handleStores = (e) => {
    let storesCode = $("#storesID").find(":selected").attr("data-key");
    setStoresCode(storesCode);
  };

  //API - Get Cart List
  const getCartList = async () => {
    let cart = JSON.parse(localStorage.getItem("CartData"));
    let cashierCode = localStorage.getItem("cashierCode");
    if (cart !== null && cart !== undefined) {
      let cartCode = cart.cartCode;
      let payload = {
        cartCode: cartCode,
        cashierCode: cashierCode,
      };
      await getCartListApi(payload)
        .then((res) => {
          setCartListData(res.data.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const settings = async () => {
    await settingsApi()
      .then((res) => setSettingsData(res.data.data))
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const canadianPostalCode = Yup.string().test(
    "is-canadian-postal-code",
    "Invalid Canadian Postal Code",
    (value) => {
      if (!value) return true;
      const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
      return postalCodeRegex.test(value);
    }
  );

  const validateSchema = Yup.object({
    phoneno: Yup.string()
      .required("Phone number is required")
      .matches(
        canadianPhoneNumberRegExp,
        "Invalid Canadian phone number format. Use (XXX) XXX-XXXX."
      ),
    customername:
      deliveryType === "pickup"
        ? null
        : Yup.string()
            .required("Customer Name is Required.")
            .matches(
              /^[A-Za-z\s]+$/,
              "Customer Name must contain only letters and spaces"
            ),
    customeremail: Yup.string().email("Invalid email address"),
    address:
      deliveryType === "pickup"
        ? null
        : Yup.string()
            .min(3, "Minimum 3 characters")
            .required("Address is Required"),
    postalcode:
      deliveryType === "pickup"
        ? null
        : canadianPostalCode.required("Postal Code is Required"),

    stores: Yup.string().required("Store Location is Required."),
    orderTakenBy: Yup.string()
      .min(3, "Minimum 3 characters")
      .required("Order Taken By is required."),
  });

  useEffect(() => {
    if (updateOrder) {
      setDeliveryType(updateOrderData?.deliveryType);
      setExtraDeliveryCharges(Number(updateOrderData?.extraDeliveryCharges));
      formik.values.phoneno = updateOrderData?.mobileNumber;
      formik.values.orderTakenBy = updateOrderData?.orderTakenBy;
      formik.values.address = updateOrderData?.address;
      formik.values.customername = updateOrderData?.customerName;
      formik.values.customeremail = updateOrderData?.customerEmail;
      formik.values.stores = updateOrderData?.storeLocationCode;
      formik.values.postalcode = updateOrderData?.zipCode;
      formik.values.deliveryExecutive = updateOrderData?.deliveryExecutiveCode;
      setTaxPer(Number(updateOrderData?.taxPer));
    } else {
      formik.values.phoneno = "";
      formik.values.orderTakenBy = "";
      formik.values.address = "";
      formik.values.customername = "";
      formik.values.customeremail = "";
      formik.values.stores = user?.role !== "R_4" ? user?.storeLocation : "";
      formik.values.postalcode = "";
      formik.values.deliveryExecutive = "";
    }
  }, [updateOrderData, updateOrder, user]);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validationSchema: validateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let cart = JSON.parse(localStorage.getItem("CartData"));
        let cashierCode = localStorage.getItem("cashierCode");

        // Call the order placing API here
        const payload = {
          cashierCode: updateOrder ? updateOrderData?.cashierCode : cashierCode,
          customerName: values.customername,
          mobileNumber: values.phoneno,
          customerEmail: values.customeremail,
          role: user?.data?.role,
          address: values.address,
          zipCode: values.postalcode,
          deliveryType: deliveryType,
          storeLocation: values.stores,
          deliveryExecutive: values.deliveryExecutive,
          orderTakenBy: values.orderTakenBy,
          products: cartData,
          subTotal: totalPrice,
          discountAmount: discount,
          taxPer: taxPer,
          taxAmount: ((discountedTotalPrice * taxPer) / 100).toFixed(2),
          deliveryCharges:
            cartData.length !== 0 && deliveryType !== "pickup"
              ? settingsData?.filter(
                  (item) => item.settingName === "Delivery Charges"
                )[0].settingValue
              : 0,
          extraDeliveryCharges: extraDeliveryCharges ? extraDeliveryCharges : 0,
          grandTotal: grandTotal,
        };

        // let response;
        const response = updateOrder
          ? await orderEditApi({
              orderCode: updateOrderData?.code,
              ...payload,
            })
          : await orderPlaceApi(payload);
        if (response.status === 200) {
          if (!updateOrder) {
            if (socket) {
              const socketOrderData = response.data.data;
              socket.emit("order-place", socketOrderData);
            }
          }

          resetForm();
          dispatch(addToCart([]));
          dispatch(setUpdateOrderData({}));
          dispatch(setUpdateOrder(false));
          setDiscount(0);
          setPrevOrders([]);
          setExtraDeliveryCharges(0);
          orderDetails({ orderCode: response.data.orderCode }).then((data) => {
            setOrderDetail(data.data.data);
            setIsOrderSubmittedSuccessfully(true);
          });
          toast.success(response.data.message);
        } else {
          toast.error("Failed to place the order");
        }
      } catch (error) {
        if (cartData?.length === 0 || cartData === undefined) {
          toast.error("Add something to cart");
        } else {
          toast.error(error.response.data.message);
        }
      }
      // }
    },
  });

  const debouncedInputValue = useDebounce(formik.values.postalcode, 2000);
  const debouncedInputValueForphoneNumber = useDebounce(
    formik.values.phoneno,
    2000
  );

  const fetchpostalcodeIsDeliverable = async (postalcode) => {
    setIsLoading(true);
    await postalCodeDeliverable(postalcode)
      .then((res) => {
        setIspostalcodeAvailable(res.data.deliverable);
        if (res.data.deliverable) {
          setIsOpen(false);
        } else if (!updateOrder) {
          setIsOpen(true);
          toast.info(
            "Postal code is not deliverable, you can add extra devlivery charges for this order"
          );
        } else {
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const fetchPrevOrder = async (phoneno) => {
    setPrevOrderLoading(true);
    await prevOrderDetails(phoneno)
      .then((res) => {
        setPrevOrders(res.data.data);
        setPrevOrderLoading(false);
      })
      .catch((err) => {
        setPrevOrderLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const getPrevAddress = async (phoneno) => {
    await getPrevAddressApi({ mobileNumber: phoneno })
      .then((res) => {
        formik.values.address = res.data.data.prevOrderAddress;
        formik.values.customername = res.data.data.customerName;
        formik.values.postalcode = res.data.data.postalCode;
      })
      .catch((error) => {
        formik.values.address = "";
        formik.values.customername = "";
        formik.values.postalcode = "";
      });
  };

  const handleTaxChange = (e) => {
    const store = storesLocationData.find(
      (store) => store.code === e.target.value
    );
    if (store) {
      setTaxPer(Number(store.province.tax_percent));
    }
  };

  useEffect(() => {
    if (formik?.values?.postalcode?.length > 0) {
      fetchpostalcodeIsDeliverable(formik.values.postalcode);
    }
  }, [debouncedInputValue]);

  useEffect(() => {
    if (formik.values?.phoneno?.length > 9) {
      fetchPrevOrder(formik.values.phoneno);
    } else {
      setPrevOrders([]);
    }
  }, [debouncedInputValueForphoneNumber]);

  useEffect(() => {
    if (!updateOrder) {
      if (formik.values?.phoneno?.length >= 9 && deliveryType === "delivery") {
        getPrevAddress(formik.values.phoneno);
      } else {
        formik.values.postalcode = "";
      }
    }
  }, [debouncedInputValueForphoneNumber, deliveryType]);

  useEffect(() => {
    if (user?.role !== "R_4" && storesLocationData.length > 0) {
      const store = storesLocationData.find(
        (store) => store.code === user?.storeLocation
      );
      if (store && !updateOrder) {
        setTaxPer(Number(store.province.tax_percent));
      }
    }
  }, [storesLocationData, user, updateOrder]);

  const refreshPage = () => {
    dispatch(setUpdateOrderData({}));
    dispatch(setUpdateOrder(false));
    window.location.reload();
  };

  const handleRadiobtn = (e) => {
    setDeliveryType(e.target.value);
  };

  useEffect(() => {
    pizzaIngredients(allIngredientsApi, setAllIngredients);
    sidesIngredient(sidesApi, setSidesData);
    pizzaPriceData(pizzaPriceApi, setPizzaPriceObj); // Developer: Shreyas Mahamuni, 23-11-2023
    storeLocation();
    getCartList();
    settings();
  }, []);

  return (
    <div className="orderContainer">
      <form onSubmit={formik.handleSubmit}>
        <div className="row g-2">
          {/* Section 1 */}

          <div className="col-lg-2 sectionOne">
            <label className="form-label mt-2 mb-1">
              Phone <small className="text-danger">*</small>{" "}
            </label>
            <IntlTelInput
              containerClassName="intl-tel-input mt-2 w-100"
              type="tel"
              name="phoneno"
              inputClassName="form-control"
              // type='number'
              placeholder="(XXX) XXX-XXXX"
              value={formik?.values?.phoneno?.replace(/\D/g, "")}
              onBlur={formik.handleBlur}
              defaultCountry="CA"
              onlyCountries={["CA"]}
              preferredCountries={["CA"]}
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                if (value.length <= 10) {
                  formik.setFieldValue("phoneno", value);
                }
              }}
            />
            {formik.touched.phoneno && formik.errors.phoneno ? (
              <div className="text-danger">{formik.errors.phoneno}</div>
            ) : null}

            <div className="my-1 p-0 d-flex justify-content-between">
              <label className="radio d-flex align-items-center my-1 w-50">
                <input
                  className="mx-2"
                  type="radio"
                  checked={deliveryType === "pickup" ? true : false}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleRadiobtn(e);
                  }}
                  name="category"
                  value="pickup"
                />
                Pickup
              </label>
              <label className="radio d-flex align-items-center my-1 mx-2 w-50">
                <input
                  className="mx-2"
                  type="radio"
                  name="category"
                  checked={deliveryType === "delivery" ? true : false}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleRadiobtn(e);
                  }}
                  value="delivery"
                />
                Delivery
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                Order Taken By <small className="text-danger">*</small>
              </label>
              <input
                className="form-control"
                type="text"
                name="orderTakenBy"
                id="orderTakenBy"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.orderTakenBy}
              />
              {formik.touched.orderTakenBy && formik.errors.orderTakenBy ? (
                <div className="text-danger my-1">
                  {formik.errors.orderTakenBy}
                </div>
              ) : null}
            </div>
            <div>
              <label className="form-label mt-2">
                Customer Name
                {deliveryType === "delivery" && (
                  <small className="text-danger">*</small>
                )}
              </label>
              <input
                className="form-control"
                type="text"
                name="customername"
                id="customername"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customername}
              />
              {formik.touched.customername && formik.errors.customername ? (
                <div className="text-danger my-1">
                  {formik.errors.customername}
                </div>
              ) : null}
            </div>
            <div>
              <label className="form-label mt-2">Customer Email</label>
              <input
                className="form-control"
                type="text"
                name="customeremail"
                id="customeremail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customeremail}
              />
              {formik.touched.customeremail && formik.errors.customeremail ? (
                <div className="text-danger my-1">
                  {formik.errors.customeremail}
                </div>
              ) : null}
            </div>
            <label className="form-label mt-2 mb-1">
              Address{" "}
              {deliveryType === "delivery" && (
                <small className="text-danger">*</small>
              )}
            </label>
            <textarea
              className="form-control"
              rows="4"
              cols="50"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="text-danger my-1">{formik.errors.address}</div>
            ) : null}
            {deliveryType === "pickup" ? null : (
              <>
                <label className="form-label mt-2 mb-1">
                  Postal Code{" "}
                  {deliveryType === "delivery" && (
                    <small className="text-danger">*</small>
                  )}
                </label>
                <input
                  className="form-control"
                  name="postalcode"
                  id="postalcode"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalcode}
                />
                <div
                  className={
                    isLoading
                      ? "spinner-border spinner-border-sm d-flex mt-1"
                      : "d-none"
                  }
                  role="status"
                ></div>
                {formik.touched.postalcode && formik.errors.postalcode ? (
                  <div className="text-danger my-1">
                    {formik.errors.postalcode}
                  </div>
                ) : null}
              </>
            )}
            {/* <NotDeliverableModel
                                extraDeliveryCharges={extraDeliveryCharges}
                                setExtraDeliveryCharges={setExtraDeliveryCharges}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                            /> */}
            <label className="form-label mt-2 mb-1">
              Store Location <small className="text-danger">*</small>
            </label>
            <select
              className="form-select"
              id="storesID"
              name="stores"
              value={formik.values.stores ?? user?.storeLocation}
              onChange={(e) => {
                handleTaxChange(e);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            >
              {user?.role === "R_4" ? (
                <>
                  <option value="">Choose Stores Location</option>
                  {storesLocationData?.map((stores) => {
                    return (
                      <option
                        key={stores.code}
                        data-key={stores.code}
                        value={stores.code}
                      >
                        {stores.storeLocation}
                      </option>
                    );
                  })}
                </>
              ) : (
                storesLocationData?.map((stores) => {
                  if (user?.storeLocation === stores?.code) {
                    return (
                      <option
                        key={stores.code}
                        data-key={stores.code}
                        value={stores.code}
                        selected
                      >
                        {stores.storeLocation}
                      </option>
                    );
                  }
                })
              )}
            </select>

            {formik.touched.stores && formik.errors.stores ? (
              <div className="text-danger my-1">{formik.errors.stores}</div>
            ) : null}

            {/* delivery executive  */}
            {deliveryType === "delivery" && (
              <>
                <label className="form-label mt-2 mb-1">
                  Delivery Executive
                </label>
                <select
                  className="form-select"
                  id="storesID"
                  name="deliveryExecutive"
                  //defaultValue={formik.values.deliveryExecutive ?? "STR_1"}
                  value={formik.values.deliveryExecutive ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Choose Delivery Executive</option>
                  {deliverExecutiveList?.map((person) => {
                    return (
                      <option
                        key={person.code}
                        data-key={person.code}
                        value={person.code}
                      >
                        {person.firstName} {person.lastName}
                      </option>
                    );
                  })}
                </select>

                {formik.touched.deliveryExecutive &&
                formik.errors.deliveryExecutive ? (
                  <div className="text-danger my-1">
                    {formik.errors.deliveryExecutive}
                  </div>
                ) : null}
              </>
            )}
            {/* Previous Order Credit Comments */}
            {prevOrderLoading ? (
              <>
                <div className="d-flex justify-content-center align-items-center my-2">
                  <div className={"spinner-border  mt-1"} role="status" />
                </div>
              </>
            ) : (
              <>
                <div
                  className="my-3 p-2 shadow-sm bg-white"
                  style={{
                    border: "3px solid #ffe1cb",
                    borderRadius: "0.25rem",
                  }}
                >
                  <h6 className="">Credit History</h6>
                  <div className="" style={{ textAlign: "justify" }}>
                    <span style={{ color: "#db6a00", fontWeight: "500" }}>
                      {prevOrders?.length > 0 ? (
                        <>
                          {prevOrders[0]?.comments === "" ? (
                            <>No any comments</>
                          ) : (
                            prevOrders[0]?.comments
                          )}
                        </>
                      ) : (
                        <>No any comments</>
                      )}
                    </span>
                  </div>
                </div>
              </>
            )}
            <h6 className="my-3">Previous Order</h6>
            <div>
              <table className="table text-center border-none">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Order</th>
                    <th scope="col">Total</th>
                    <th scope="col">Credits</th>
                  </tr>
                </thead>
                <tbody>
                  {prevOrderLoading ? (
                    <tr>
                      <td colSpan="3">
                        <div className={"spinner-border  mt-1"} role="status" />
                      </td>
                    </tr>
                  ) : prevOrders?.length > 0 ? (
                    prevOrders?.map((order) => {
                      return (
                        <tr
                          key={order?.code}
                          className="bg-white text-dark "
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(addToCart(order?.orderItems));
                          }}
                        >
                          <td className="px-0">
                            {order?.created_at?.split(" ")[0]}
                          </td>
                          <td className="px-0">{order?.orderCode}</td>
                          <td className="px-0">{order?.grandTotal}</td>
                          <td className="px-0">{order?.comments}</td>
                        </tr>
                      );
                    })
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
          {/* Section 2 */}
          <div className="col-lg-6 my-1 sectionTwo">
            {/* Tabs Headings */}
            <ProductTabNav
              createYourOwnRef={createYourOwnRef}
              specialTabRef={specialTabRef}
              signatureTabRef={signatureTabRef}
              otherPizzaTabRef={otherPizzaTabRef}
              sidesRef={sidesRef}
              dipsRef={dipsRef}
              drinksRef={drinksRef}
              payloadEdit={payloadEdit}
              setPayloadEdit={setPayloadEdit}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              section={section}
              setSection={setSection}
            />
            {/* Tab Content */}
            <div className="tab-content m-0 p-0 mt-3 w-100">
              {/* Create Your Own */}
              <div
                id="createByOwn"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "createByOwn" ? "active" : ""
                }`}
              >
                <CreateYourOwn
                  allIngredients={allIngredients}
                  sidesData={sidesData}
                  pizzaPriceObj={pizzaPriceObj} // Developer: Shreyas Mahamuni, 22-11-2023
                  discount={discount}
                  taxPer={taxPer}
                  getCartList={getCartList}
                  isEdit={isEdit}
                  cartListData={cartListData}
                  cartCode={cartCode}
                  cartLineCode={cartLineCode}
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                  setAllIngredients={setAllIngredients}
                  setOrderData={setOrderData}
                />
              </div>

              {/* SpecialMenu */}
              <div
                id="special"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "special" ? "active" : ""
                }`}
              >
                <SpecialMenu
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                  specialTabRef={specialTabRef}
                  setSection={setSection}
                />
              </div>

              <div
                id="signature"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "signature" ? "active" : ""
                }`}
              >
                <SignaturePizza
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                  signatureTabRef={signatureTabRef}
                  setSection={setSection}
                />
              </div>

              <div
                id="otherpizza"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "otherpizza" ? "active" : ""
                }`}
              >
                <OtherPizza
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                  otherPizzaTabRef={otherPizzaTabRef}
                  setSection={setSection}
                />
              </div>

              {/* All SIdes */}
              <div
                id="sidesMenu"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "sidesMenu" ? "active" : ""
                }`}
              >
                <SidesMenu
                  getCartList={getCartList}
                  discount={discount}
                  taxPer={taxPer}
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                />
              </div>

              {/* All Dips */}
              <div
                id="dipsMenu"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "dipsMenu" ? "active" : ""
                }`}
              >
                <DipsMenu
                  getCartList={getCartList}
                  discount={discount}
                  taxPer={taxPer}
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                />
              </div>

              {/* All Drinks */}
              <div
                id="drinksMenu"
                className={`container tab-pane m-0 p-0 ${
                  activeTab === "drinksMenu" ? "active" : ""
                }`}
              >
                <DrinksMenu
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                  getCartList={getCartList}
                  discount={discount}
                  taxPer={taxPer}
                />
              </div>
            </div>
          </div>

          {/* Section 3 */}
          <div
            className="col-lg-4 mt-1"
            style={{ backgroundColor: "#ff8c008as !important" }}
          >
            <div className="d-flex pt-2">
              <div className="col-5 px-3">
                <h6 className="fs-5 fw-bold">Cart</h6>
              </div>
              <div className="col-7">
                <div className="d-flex justify-content-end">
                  {cartData?.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-xs ms-5 "
                      onClick={() => {
                        dispatch(addToCart([]));
                        dispatch(setUpdateOrderData({}));
                        dispatch(setUpdateOrder(false));
                        setPayloadEdit();
                      }}
                    >
                      <BiTrash /> Clear cart
                    </button>
                  )}
                  {updateOrder && (
                    <button
                      type="button"
                      className="btn btn-dark btn-xs ms-1"
                      onClick={() => {
                        dispatch(addToCart([]));
                        setPayloadEdit();
                        refreshPage();
                      }}
                    >
                      Cancel edit
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Add to Cart */}
            <div className="d-flex flex-column cart">
              <div
                className="p-2 rounded mb-3 overflow-auto"
                style={{
                  minHeight: "calc(100% - 40%)",
                  backgroundColor: "#ff8c0026",
                }}
              >
                <Cart
                  onProductClick={handleProductClick}
                  payloadEdit={payloadEdit}
                  setPayloadEdit={setPayloadEdit}
                  setSection={setSection}
                />
              </div>

              {/* Order Submit */}
              <div className="">
                {/* Price / Sub Total */}
                <div className="d-flex flex-wrap my-2 justify-content-end align-items-center OrderAmount">
                  <label className="form-label w-25">Price</label>
                  <div className="input-group w-75">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputGroupTxt px-2">
                        $
                      </span>
                    </div>
                    <input
                      className="form-control w-25 text-end"
                      type="number"
                      placeholder="0.00"
                      step="0.01"
                      readOnly
                      value={Number(totalPrice).toFixed(2)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text inputGroupTxt">
                        CAD
                      </span>
                    </div>
                  </div>
                </div>

                {/* Discount */}
                <div className="d-flex flex-wrap my-2 my-2 justify-content-end align-items-center">
                  <label className="form-label w-25">Discount</label>
                  <div className="input-group w-75">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputGroupTxt px-2">
                        $
                      </span>
                    </div>
                    <input
                      className="form-control w-25 text-end"
                      type="number"
                      placeholder="0.00"
                      step="1"
                      max={totalPrice.toFixed(2)}
                      value={discount}
                      onChange={(e) => {
                        let inputValue = Number(e.target.value);
                        if (inputValue <= 0) {
                          inputValue = "";
                        } else if (inputValue > totalPrice) {
                          inputValue = totalPrice;
                        }
                        setDiscount(inputValue);
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text inputGroupTxt">
                        CAD
                      </span>
                    </div>
                  </div>
                </div>

                {/* Tax Percentage */}
                <div className="d-flex flex-wrap my-2 justify-content-end align-items-center">
                  <label className="form-label w-25">
                    <span>Tax</span>
                    <span className="ms-2">
                      <b>{taxPer} %</b>
                    </span>
                  </label>
                  <div className="input-group w-75">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputGroupTxt px-2">
                        $
                      </span>
                    </div>
                    <input
                      className="form-control w-25 text-end"
                      type="number"
                      readOnly
                      placeholder="0.00"
                      min="0"
                      step="1"
                      //defaultValue={0}
                      value={
                        cartData.length !== 0
                          ? ((discountedTotalPrice * taxPer) / 100).toFixed(2)
                          : 0
                      }
                    ></input>
                    <div className="input-group-append">
                      <span className="input-group-text inputGroupTxt">
                        CAD
                      </span>
                    </div>
                  </div>
                </div>

                {/* Grand Total / Total Price */}
                {deliveryType === "delivery" ? (
                  <div className="d-flex flex-wrap my-2 justify-content-end align-items-center">
                    <label className="form-label w-25">Delivery Charges</label>
                    <div className="input-group w-75">
                      <div className="input-group-prepend">
                        <span className="input-group-text inputGroupTxt px-2">
                          $
                        </span>
                      </div>
                      <input
                        className="form-control w-25 text-end"
                        type="number"
                        placeholder="0.00"
                        min="0"
                        step="0.01"
                        value={
                          cartData.length === 0 || deliveryType == "pickup"
                            ? 0
                            : settingsData?.filter(
                                (item) =>
                                  item.settingName === "Delivery Charges"
                              )[0].settingValue
                        }
                      />
                      <div className="input-group-append">
                        <span className="input-group-text inputGroupTxt">
                          CAD
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {deliveryType === "delivery" && !ispostalcodeAvailable ? (
                  <div className="d-flex flex-wrap my-2 justify-content-end align-items-center">
                    <label className="form-label w-25">
                      Extra Delivery Charges
                    </label>
                    <div className="input-group w-75">
                      <div className="input-group-prepend">
                        <span className="input-group-text inputGroupTxt px-2">
                          $
                        </span>
                      </div>
                      <input
                        className="form-control w-25 text-end"
                        type="number"
                        placeholder="0.00"
                        min="0"
                        step="1"
                        value={extraDeliveryCharges}
                        onChange={(e) =>
                          setExtraDeliveryCharges(
                            e.target.value < 0
                              ? 0
                              : e.target.value > 999
                              ? 999
                              : e.target.value
                          )
                        }
                      />
                      <div className="input-group-append">
                        <span className="input-group-text inputGroupTxt">
                          CAD
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="d-flex flex-wrap my-2 justify-content-end align-items-center">
                  <label className="form-label w-25">Total Price</label>
                  <div className="input-group w-75">
                    <div className="input-group-prepend">
                      <span className="input-group-text inputGroupTxt px-2">
                        $
                      </span>
                    </div>
                    <input
                      className="form-control w-25 text-end"
                      type="number"
                      placeholder="0.00"
                      min="0"
                      step="0.01"
                      value={Number(grandTotal).toFixed(2)}
                      readOnly
                    ></input>
                    <div className="input-group-append">
                      <span className="input-group-text inputGroupTxt">
                        CAD
                      </span>
                    </div>
                  </div>
                </div>

                {/* Submit Order */}
                <div className="d-flex flex-row justify-content-end align-items-center">
                  <button
                    type="button"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                    className="submitOrderbtn btn btn-sm mb-4 px-4 py-2"
                  >
                    {formik.isSubmitting ? "Please wait..." : "Submit Order"}
                  </button>
                  <div className="d-none">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          ref={btnRef}
                          type="button"
                          disabled={formik.isSubmitting}
                          className="submitOrderbtn btn btn-sm mx-3 my-3 px-4 py-2"
                        >
                          {formik.isSubmitting ? "Please wait..." : "Print"}
                        </button>
                      )}
                      content={() => printRef2?.current}
                      onAfterPrint={() => {
                        refreshPage();
                      }}
                    ></ReactToPrint>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Print printRef={printRef2} orderDetail={orderDetail} />
    </div>
  );
}

export default NewOrder;
