import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSocket } from "../../context/SocketContext";
import { getHighlightOrders } from "../../API/http";
import OrderCard from "./OrderCard";
import { Howl } from "howler";
import logo from "../../assets/logo.png";
import "./style.css";

const OrderList = () => {
  const user = useSelector((state) => state.user.userData);
  const role = user?.role ?? "R_3";
  const storeLocation = user?.storeLocation ?? "STR_1";

  const [progressOrders, setProgressOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);

  const navigate = useNavigate();

  const socket = useSocket();

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => setFullscreen(true));
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => setFullscreen(false));
      }
    }
  };

  const redirectBack = () => {
    navigate("/ongoing-orders");
  };

  const loadExistingOrders = async () => {
    const result = await getHighlightOrders(storeLocation);
    if (result.status === 200) {
      let data = result.data.data;
      const placedOrders = data.filter((order) => order.status === "placed");
      if (placedOrders.length > 0) setProgressOrders(placedOrders);
      const readyOrders = data.filter((order) => order.status === "ready");
      if (readyOrders.length > 0) setReadyOrders(readyOrders);
    }
  };

  useEffect(() => {
    loadExistingOrders();
  }, []);

  useEffect(() => {
    // handlePermissionRequest();
    // Listen for 'message' event from server
    socket.on("order-screen", (data) => {
      // debugger
      if (data.deliveryType === "pickup") {
        if (role === "R_3" && storeLocation === data.storeCode) {
          if (data.status === "placed") {
            setProgressOrders([...progressOrders, data]);
            progressOrders.sort((a, b) => (a.orderCode > b.orderCode ? 1 : -1));
          } else if (data.status === "ready") {
            const orderIndex = progressOrders.findIndex(
              (order) => order.orderNumber === data.orderNumber
            );
            setProgressOrders(
              progressOrders.filter((order) => order.id === data.orderNumber)
            );
            const orderToMove = progressOrders[orderIndex];
            if (orderToMove) {
              orderToMove.status = data.status;
              setReadyOrders([...readyOrders, orderToMove]);
            }
          } else if (data.status === "cancelled") {
            setProgressOrders(
              progressOrders.filter(
                (order) => order.orderNumber !== data.orderNumber
              )
            );
            setReadyOrders(
              readyOrders.filter(
                (order) => order.orderNumber !== data.orderNumber
              )
            );
          } else {
            setReadyOrders(
              readyOrders.filter(
                (order) => order.orderNumber !== data.orderNumber
              )
            );
          }
        }
      }
    });

    // Clean up on unmount
    return () => {
      socket.off("order-from-store");
    };
  }, [socket, progressOrders, readyOrders]);

  return (
    <div className="container-fluid vh-100">
      <div className="row">
        <div className="col">
          <h5 className="mt-1" style={{ color: "#ffaa00" }}>
            <img src={logo} width="30px" height="30px" alt="" />
            <span className="ms-2">Mr.SinghsPizza</span>
          </h5>
        </div>
        <div className="col text-end">
          <button
            className="btn btn-sm btn-danger m-2"
            type="button"
            onClick={handleFullScreen}
          >
            {fullscreen ? "Exit Full Screen" : "Full Screen"}
          </button>
          {!fullscreen && (
            <button
              className="btn btn-sm btn-dark"
              type="button"
              onClick={redirectBack}
            >
              Back
            </button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>In Progress</h4>
          <div className="hr-seprator grey mb-3"></div>
          <div className="box-border">
            {progressOrders.map((order, index) => {
              return (
                <OrderCard order={order} indexKey={index} key={"ord" + index} />
              );
            })}
          </div>
        </div>
        <div className="col">
          <h4>Ready For Pick Up</h4>
          <div className="hr-seprator green mb-3"></div>
          <div className="box-border ready">
            {readyOrders.map((order, index) => {
              return (
                <OrderCard order={order} indexKey={index} key={"ord" + index} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
