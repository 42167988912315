import React, { useEffect, useState } from "react";
import { sidesApi, groupedSidesApi } from "../../API/http";
import $ from "jquery";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../reducer/cartReducer";
import "../../css/sides.css";

function SidesMenu({
  discount,
  taxPer,
  payloadEdit,
  setPayloadEdit,
  section,
  setSection,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [sidesData, setSidesData] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [sidesArr, setSidesArr] = useState([]);
  const [comments, setComments] = useState("");
  const [groupedSides, setGroupedSides] = useState([]);
  const [type, setType] = useState("");
  const [search, setSearch] = useState("");
  const [prId, setPrId] = useState(null)
  let cartdata = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();

  let filteredSides = groupedSides
    .map((group) => {
      // Filter sides within each group based on the search term
      const filteredSides = group.sides.filter((side) =>
        side.sideName.toLowerCase().includes(search.toLowerCase())
      );
      // Return the group only if it matches the selected type and has filtered sides
      if ((type === "" || group.type === type) && filteredSides.length > 0) {
        return { ...group, sides: filteredSides };
      }
      return null;
    })
    .filter((group) => group !== null); // Remove null groups

  // handle Quantity
  const handleQuantity = (e, side) => {
    // const inputValue = e.target.value;
    let index = sidesArr?.findIndex((item) => item.sideCode === side.sideCode);
    if (index !== -1) {
      let arr = [...sidesArr];
      arr[index].qty = e.target.value <= 0 ? 1 : e.target.value;
      setSidesArr(arr);
      updateInCart({
        ...arr[index],
        qty: e.target.value <= 0 ? 1 : e.target.value,
      });
    } else {
      setSidesArr([
        ...sidesArr,
        { ...side, qty: e.target.value <= 0 ? 1 : e.target.value },
      ]);
      updateInCart({ ...side, qty: e.target.value <= 0 ? 1 : e.target.value });
    }
  };

  const handleSidesLineChange = (e, data) => {
    let index = sidesArr?.findIndex((item) => item.sideCode === data.sideCode);
    let selectedSideLine = sidesArr[index]?.combination?.filter(
      (item) => item.lineCode === e.target.value
    );
    let obj = sidesData.find((item) => item.sideCode === data.sideCode);
    if (index !== -1) {
      let arr = [...sidesArr];

      arr[index].combination = obj.combination.filter(
        (item) => item.lineCode === e.target.value
      );
      setSidesArr(arr);
      updateInCart({
        ...arr[index],
        combination: obj.combination.filter(
          (item) => item.lineCode === e.target.value
        ),
      });
    } else {
      let selectedSideLine = data?.combination?.filter(
        (item) => item.lineCode === e.target.value
      );
      setSidesArr([{ ...data, combination: selectedSideLine }]);
      updateInCart({ ...data, combination: selectedSideLine });
    }
  };

  const updateInCart = (data) => {
    let cart = JSON.parse(localStorage.getItem("CartData"));

    let tempPayload = [...cartdata];

    const updatedCartId = cartdata?.findIndex(
      (item) => item?.productCode === data?.sideCode
    );
    let cartCode;
    let customerCode;
    if (cart !== null && cart !== undefined) {
      cartCode = cart?.cartCode;
      customerCode = cart?.customerCode;
    }
    let price = data?.combination[0]?.price;

    let totalAmount = 0;

    totalAmount =
      Number(price) * (data?.qty !== undefined ? Number(data?.qty) : 1);

    const payload = {
      id: updatedCartId !== -1 ? cartdata[updatedCartId]?.id : uuidv4(),
      cartCode: cartCode ? cartCode : "#NA",
      customerCode: customerCode ? customerCode : "#NA",
      cashierCode: localStorage.getItem("cashierCode"),
      productCode: data?.sideCode,
      productName: data?.sideName,
      productType: "side",
      config: {
        lineCode: data?.combination[0]?.lineCode,
        sidesSize: data?.combination[0]?.size,
        sideType: data?.type,
      },
      quantity: data?.qty ? data?.qty : 1,
      price: data?.combination[0]?.price,
      amount: totalAmount.toFixed(2),
      discountAmount: discount,
      taxPer: taxPer,
      pizzaSize: "",
      comments: data?.comment ? data?.comment : "",
    };

    if (updatedCartId !== -1) {
      tempPayload[updatedCartId] = payload;
    } else {
      tempPayload.unshift(payload);
    }
    dispatch(addToCart([...tempPayload]));
  };

  // Onclick handle Add To Cart & API - Add To Cart
  const handleAddToCart = async (e, sideCode, Obj) => {
    if (
      payloadEdit !== undefined &&
      payloadEdit.productType === "side" &&
      payloadEdit?.productCode != Obj?.sideCode
    ) {
      toast.error("complete your last edit first");
      return;
    } else {
      e.preventDefault();
      const updatedCartId = cartdata?.findIndex(
        (item) => item?.productCode === Obj?.sideCode
      );
      let cart = JSON.parse(localStorage.getItem("CartData"));
      let cartCode;
      let customerCode;
      let lineCode = $("#combination-" + sideCode)
        .find(":selected")
        .attr("data-key");

      if (cart !== null && cart !== undefined) {
        cartCode = cart.cartCode;
        customerCode = cart.customerCode;
      }

      const selectedSideForNewItem = sidesArr?.filter(
        (sides) => sides.sideCode === sideCode
      );
      const selectedCombination =
        selectedSideForNewItem[0]?.combination?.filter(
          (data) => data.lineCode === lineCode
        );

      const selectedCombinationObj = Obj?.combination?.filter(
        (data) => data.lineCode === lineCode
      );

      let price =
        selectedCombination !== undefined && selectedCombination[0].price
          ? selectedCombination[0]?.price
          : selectedCombinationObj[0]?.price;

      let totalAmount = 0;

      totalAmount =
        Number(price) *
        (selectedSideForNewItem[0]?.qty !== undefined
          ? Number(selectedSideForNewItem[0]?.qty)
          : 1);

      if (selectedSideForNewItem.length === 0) {
        const payload = {
          id: uuidv4(),
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: Obj.sideCode,
          productName: Obj?.sideName,
          productType: "side",
          config: {
            lineCode: selectedCombinationObj[0].lineCode,
            sidesSize: selectedCombinationObj[0].size,
            sidesType: Obj?.type,
          },
          quantity: 1,
          price: selectedCombinationObj[0].price,
          amount: totalAmount.toFixed(2),
          discountAmount: discount,
          taxPer: taxPer,
          pizzaSize: "",
          comments: "",
        };
        filteredSides = groupedSides;
        setSidesArr([]);
        dispatch(addToCart([payload, ...cartdata]));
        toast.success(`${Obj.sideName} Added Successfully`);
        return;
      }

      setSidesArr([
        ...sidesArr,
        {
          ...selectedSideForNewItem,
          qty: 1,
        },
      ]);

      const selectedSide = sidesArr?.filter(
        (sides) => sides.sideCode === sideCode
      );

      if (payloadEdit !== undefined && payloadEdit.productType === "side") {
        const payloadForEdit = {
          id: payloadEdit?.id,
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: selectedSide[0].sideCode,
          productName: selectedSide[0].sideName,
          productType: "side",
          config: {
            lineCode: selectedSide[0].combination[0].lineCode,
            sidesSize: selectedSide[0].combination[0].sidesSize
              ? selectedSide[0].combination[0].sidesSize
              : selectedSide[0].combination[0].size,
            sideType:
              selectedSide?.[0]?.type ??
              selectedSide?.[0]?.sidesType ??
              selectedSide?.[0]?.sideType,
          },
          quantity: selectedSide[0].qty,
          price: selectedSide[0].price,
          amount: totalAmount.toFixed(2),
          discountAmount: discount,
          taxPer: taxPer,
          pizzaSize: "",
          comments: selectedSide[0].comment,
        };
        const updatedCart = cartdata.findIndex(
          (item) => item.id === payloadEdit.id
        );
        let tempPayload = [...cartdata];
        tempPayload[0] = payloadForEdit;
        dispatch(addToCart([...tempPayload]));
        setPayloadEdit();
        filteredSides = groupedSides;
        setSidesArr([]);
        setQuantity(1);
      } else {
        let tempPayload = [...cartdata];

        const payload = {
          id: updatedCartId !== -1 ? cartdata[updatedCartId].id : uuidv4(),
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: selectedSideForNewItem[0]?.sideCode,
          productName: selectedSideForNewItem[0]?.sideName,
          productType: "side",
          config: {
            lineCode: selectedCombination[0]?.lineCode,
            sidesSize: selectedCombination[0]?.size,
            sideType: selectedSideForNewItem[0]?.type,
          },
          quantity: selectedSideForNewItem[0]?.qty
            ? selectedSideForNewItem[0]?.qty
            : 1,
          price: selectedCombination[0]?.price,
          amount: totalAmount.toFixed(2),
          discountAmount: discount,
          taxPer: taxPer,
          pizzaSize: "",
          comments: selectedSideForNewItem[0].comment,
        };
        // let temp = sidesData.map((item) => {
        //   return {
        //     ...item,
        //     qty: 1,
        //   };
        // });
        // setSidesData(temp);
        filteredSides = groupedSides;
        setSidesArr([]);
        // let
        if (updatedCartId !== -1) {
          tempPayload[updatedCartId] = payload;
        } else {
          tempPayload.unshift(payload);
        }
        dispatch(addToCart([...tempPayload]));
        toast.success(
          `${selectedSideForNewItem[0]?.sideName} Added Successfully`
        );
      }
    }
  };

  //API - Sides
  const sides = () => {
    groupedSidesApi(search)
      .then((res) => {
        const result = res.data;
        if (result.status === 200) {
          const data = result.data;
          if (data.length > 0) {
            const groupedSides = data.map((item) => {
              const sides = item.sides.map((side) => {
                return {
                  ...side,
                  qty: 1,
                };
              });
              return {
                ...item,
                sides: sides,
              };
            });
            setGroupedSides(groupedSides);
          } else {
            setGroupedSides([]);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const findSide = (sideCode) => {
    // Search for the sideCode in the sidesData
    for (const category of filteredSides) {
      const result = category.sides.find((item) => item.sideCode === sideCode);
      if (result) return result; // Return the found object
    }
    return null; // Return null if not found
  };

  const handleComment = (e, data) => {
    let index = sidesArr?.findIndex((item) => item.sideCode === data.sideCode);
    let selectedSideLine = sidesArr[index]?.combination?.filter(
      (item) => item.lineCode === e.target.value
    );
    let obj = findSide(data.sideCode);
    if (index !== -1) {
      let arr = [...sidesArr];
      arr[index] = { ...arr[index], comment: e.target.value };
      setSidesArr(arr);
    } else {
      setSidesArr([{ ...obj, comment: e.target.value }]);
    }
  };

  const clearFilter = () => {
    setSearch("");
    setType("");
  };

  useEffect(() => {
    if (payloadEdit !== undefined && payloadEdit.productType === "side") {
      setPrId(payloadEdit?.id);
      setSidesArr([
        {
          sideCode: payloadEdit?.productCode,
          sideName: payloadEdit?.productName,
          price: payloadEdit?.price,
          qty: payloadEdit?.quantity,
          combination: [
            {
              ...payloadEdit?.config,
              price: payloadEdit?.price,
              size: payloadEdit?.config?.sidesSize,
            },
          ],
          comment: payloadEdit?.comments,
        },
      ]);
      clearFilter();
    } else {
      setPrId(uuidv4());
    }
  }, [payloadEdit]);

  useEffect(() => {
    sides();
  }, []);

  if (isLoading)
    return (
      <div
        className="spinner-border spinner-border-sm d-flex mt-1"
        role="status"
      ></div>
    );

  return (
    <div className="d-flex flex-column px-2">
      <div className="row g-2 mb-3 ms-2">
        <div className="col-4">
          <label htmlFor="type">Type</label>
          <select
            name="type"
            id="type"
            className="form-select"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option key={`side-group-initial}`} value="">
              --Select--
            </option>
            {groupedSides.map((item) => {
              return (
                <option key={`side-group-${item.type}`} value={item.type}>
                  {item.type.toUpperCase()}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-6">
          <label htmlFor="search">Search</label>
          <input
            type="text"
            name="search"
            id="search"
            className="form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-2 text-end">
          <button
            type="button"
            className="btn btn-xs btn-outline-danger mt-3"
            onClick={clearFilter}
          >
            <i className="fa fa-search"></i> Clear
          </button>
        </div>
      </div>

      <div className="scroll-y h-70vh">
        {filteredSides.length > 0 &&
          filteredSides.map((group, groupIndex) => {
            const groupSides = group.sides;
            return (
              <div className="sides-group" key={`sides-${groupIndex}`}>
                <h5 className="sides-group-title">{group.type}</h5>
                <ul key={`sides-menu`} className="list-group">
                  {groupSides.map((data, gsIndex) => {
                    let comm = sidesArr?.findIndex(
                      (item) => item.sideCode === data.sideCode
                    );
                    let obj = sidesArr?.find(
                      (item) => item.sideCode === data.sideCode
                    );
                    return (
                      <li
                        key={data.sideCode + "sidesData" + gsIndex}
                        className="list-group-item"
                      >
                        <div className="d-flex justify-content-between align-items-end py-2 px-1">
                          <div className="d-flex justify-content-center flex-column py-1 w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="mb-2">
                                <span>{data.sideName}</span>
                                <span className={"badge-" + data.type}>
                                  ({data.type})
                                </span>
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <select
                                className="form-select"
                                style={{ width: "35%" }}
                                key={"combination-" + data.sideCode + gsIndex}
                                id={"combination-" + data.sideCode}
                                onChange={(e) => {
                                  handleSidesLineChange(e, data);
                                }}
                                value={
                                  comm !== -1
                                    ? sidesArr[comm]?.combination[0]?.lineCode
                                    : undefined
                                }
                              >
                                {data?.combination?.map(
                                  (combinationData, indexCombination) => {
                                    return (
                                      <option
                                        key={`side-combination-${gsIndex}-${indexCombination}`}
                                        data-key={combinationData.lineCode}
                                        data-price={combinationData.price}
                                        value={combinationData.lineCode}
                                      >
                                        {`${combinationData.size} - $${combinationData.price}`}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <input
                                type="number"
                                className="form-control"
                                style={{ width: "20%" }}
                                onChange={(e) => handleQuantity(e, data)}
                                step={1}
                                min={1}
                                value={obj !== undefined ? obj.qty : data.qty}
                                max={100}
                              />
                              <button
                                type="button"
                                className="btn btn-sm customize py-1 px-2"
                                style={{ width: "auto" }}
                                onClick={(e) =>
                                  handleAddToCart(e, data.sideCode, data)
                                }
                              >
                                {payloadEdit !== undefined &&
                                  payloadEdit.productType === "side" &&
                                  obj !== undefined
                                  ? "Edit"
                                  : "Add To Cart"}
                              </button>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <input
                                id="comment"
                                type="text"
                                value={
                                  obj?.comment !== undefined ? obj?.comment : ""
                                }
                                className="form-control mt-2"
                                onChange={(e) => handleComment(e, data)}
                                placeholder="eg. comment"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default SidesMenu;
