import React, { useEffect, useRef, useState } from "react";
import { softDrinksApi } from "../../API/http";
import specialImg1 from "../../assets/bg-img.jpg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../reducer/cartReducer";
import { v4 as uuidv4 } from "uuid";
import { addToCartAndResetQty } from "./drinksMenuFunctions/drinksMenuFunction";
import CurrentDrinks from "../../components/CurrentDrinks";

function DrinksMenu({
  discount,
  taxPer,
  setPayloadEdit,
  payloadEdit
}) {
  const [softDrinksData, setSoftDrinksData] = useState();
  const [comment, setComment] = useState("");

  const [drinksArr, setDrinksArr] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    drinks();
  }, []);

  // handle Quantity

  useEffect(() => {
    // data();
  }, [drinksArr]);

  useEffect(() => {
    if (payloadEdit !== undefined && payloadEdit.productType === "drinks") {
      setDrinksArr([
        {
          softdrinkCode: payloadEdit?.productCode,
          softDrinksName: payloadEdit?.productName,
          price: payloadEdit?.price,
          qty: payloadEdit?.quantity,
          drinkType: payloadEdit?.config?.type,
          comment: payloadEdit?.comments,
        },
      ]);
    }
  }, [payloadEdit]);

  // API - SoftDrinks
  const drinks = () => {
    softDrinksApi()
      .then((res) => {
        setSoftDrinksData(res.data.data);
      })
      .catch((err) => {
        console.error("ERROR From SoftDrinksMenu API: ", err);
      });
  };

  useEffect(() => {}, [drinksArr]);

  return (
    <ul
      key="drinks-menu"
      className="list-group"
      style={{ overflowY: "scroll", height: "70vh" }}
    >
      {softDrinksData?.map((data, index) => {
        return (
          <CurrentDrinks
            key={`soft-drink-${index}`}
            data={data}
            payloadEdit={payloadEdit}
            setPayloadEdit={setPayloadEdit}
            discount={discount}
            taxPer={taxPer}
            index={index}
          />
        );
      })}
    </ul>
  );
}

export default DrinksMenu;
