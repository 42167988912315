import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { addToCartAndResetQty } from "../Pages/ongoingOrders/drinksMenuFunctions/drinksMenuFunction";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../reducer/cartReducer";

const  CurrentDrinks = ({
  data,
  setPayloadEdit,
  payloadEdit,
  discount,
  taxPer,
  index
}) => {
  const [currentComments, setCurrentComments] = useState("");
  const selectedQuantityRef = useRef(null);

  let cartdata = useSelector((state) => state.cart.cart);
  let dispatch = useDispatch();

  // Hadle Comments
  const handleComments = (e) => {
    setCurrentComments(e.target.value);
  };

  // Handle Quantity
  const handleQuantity = () => {
    let value = parseInt(selectedQuantityRef.current.value, 10);
    if (value < 1 || isNaN(value)) {
      value = 1;
    }
    if (value > 99) {
      value = 99;
    }
    selectedQuantityRef.current.value = value;
  };

  const handleAddToCart = () => {
    let cart = JSON.parse(localStorage.getItem("CartData"));
    let cartCode;
    let customerCode;
    if (cart !== null && cart !== undefined) {
      cartCode = cart.cartCode;
      customerCode = cart.customerCode;
    }

    if (
      payloadEdit !== undefined &&
      payloadEdit.productType === "drinks" &&
      payloadEdit?.productCode != data.softdrinkCode
    ) {
      toast.error("complete your last edit first");
      return;
    } else {
      if (
        payloadEdit !== undefined &&
        payloadEdit.productType === "drinks" &&
        payloadEdit?.productCode == data.softdrinkCode
      ) {
        const payloadForEdit = {
          id: payloadEdit?.id,
          cartCode: cartCode ? cartCode : "#NA",
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: data?.softdrinkCode,
          productName: data?.softDrinksName,
          productType: "drinks",
          quantity: Number(selectedQuantityRef.current.value) ?? 1,
          config: {
            type: "",
          },
          price: data?.price,
          amount:
            Number(data?.price ? data.price : 0.0) *
            Number(selectedQuantityRef.current.value ?? 1),
          discountAmount: Number(discount),
          taxPer: Number(taxPer),
          pizzaSize: "",
          comments: currentComments ?? "",
        };

        const updateIndex = cartdata.findIndex(
          (item) => item.id === payloadEdit.id
        );
        let tempPayload = [...cartdata];

        tempPayload[updateIndex] = payloadForEdit;
        dispatch(addToCart([...tempPayload]));
        toast.success(`${data?.softDrinksName} ` + "updated successfully.");
        reset();
        setPayloadEdit();
      } else {
        const payload = {
          id: uuidv4(),
          cartCode: cartCode ? cartCode : "#NA",
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: data?.softdrinkCode,
          productName: data?.softDrinksName,
          productType: "drinks",
          quantity: Number(selectedQuantityRef.current.value) ?? 1,
          config: {
            type: "",
          },
          price: data?.price,
          amount:
            Number(data?.price ? data.price : 0.0) *
            Number(selectedQuantityRef.current.value ?? 1),
          discountAmount: Number(discount),
          taxPer: Number(taxPer),
          pizzaSize: "",
          comments: currentComments ?? "",
        };

        dispatch(addToCart([payload, ...cartdata]));
        toast.success(`${data?.softDrinksName} ` + "added successfully.");
        reset();
        setPayloadEdit();
      }
    }
  };

  const reset = () => {
    setCurrentComments("");
    selectedQuantityRef.current.value = 1;
  };

  // Populate PayloadEdit
  useEffect(() => {
    if (
      payloadEdit !== undefined &&
      payloadEdit.productType === "drinks" &&
      payloadEdit.productCode === data.softdrinkCode
    ) {
      selectedQuantityRef.current.value = payloadEdit.quantity;
      setCurrentComments(payloadEdit.comments);
    }
  }, [payloadEdit]);

  return (
    <li className="list-group-item" key={`drink-${index}`}>
      <div className="d-flex justify-content-between align-items-end py-2 px-1">
        <div className="d-flex justify-content-center flex-column py-1 w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-2">{data.softDrinksName}</h6>
            <h6 className="mb-2">$ {data.price}</h6>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="number"
              defaultValue={1}
              key={'dQty' + data.softdrinkCode + index}
              className="form-control"
              style={{ width: "20%" }}
              ref={selectedQuantityRef}
              onChange={handleQuantity}
            />
            <button
              type="button"
              key={'dBtn' + data.softdrinkCode + index}
              className="btn btn-sm customize py-1 px-2"
              style={{ width: "auto" }}
              onClick={handleAddToCart}
            >
              {payloadEdit !== undefined &&
                payloadEdit.productType === "drinks" &&
                payloadEdit.productCode === data?.softdrinkCode
                ? "Edit"
                : "Add To Cart"}
            </button>
          </div>
          <div className="d-flex w-100 ">
            <input
              type="text"
              onChange={(e) => handleComments(e)}
              key={'dCmt' + data.softdrinkCode + index}
              value={currentComments}
              className="form-control mt-2"
              placeholder="comment"
            />
          </div>
        </div>
      </div>
    </li>
  );
}

export default CurrentDrinks;
